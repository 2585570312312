.contenedor-filters {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  padding: 1rem 2rem;
  margin: 1rem auto;
  max-width: 1200px;
  transition: all 0.3s ease;
}

.filters-row {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  width: 100%;
  flex-wrap: wrap;
}

.filter {
  display: flex;
  align-items: center;
  gap: 1rem;
  position: relative;
}

.filter label {
  color: #4a5568;
  font-size: 0.85rem;
  font-weight: 500;
  white-space: nowrap;
  letter-spacing: 0.4px;
  opacity: 0.9;
}

.dropdown {
  padding: 0.6rem 1rem;
  border: 1.5px solid rgba(226, 232, 240, 0.8);
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.6);
  color: #2d3748;
  font-size: 0.9rem;
  width: 140px;
  cursor: pointer;
  appearance: none;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%234a5568' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.8rem center;
  background-size: 1rem;
  padding-right: 2.5rem;
  transition: all 0.25s ease;
  backdrop-filter: blur(8px);
}

.dropdown:hover {
  border-color: rgba(128, 90, 213, 0.8);
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0 2px 8px rgba(140, 122, 230, 0.08);
}

.dropdown:focus {
  outline: none;
  border-color: rgba(128, 90, 213, 0.9);
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0 0 0 3px rgba(128, 90, 213, 0.15);
}

.filter-icon {
  color: #805ad5;
  font-size: 1.1rem;
  transition: transform 0.2s ease;
}

.filter:hover .filter-icon {
  transform: scale(1.1);
}

.custom-button {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem 1.25rem;
  margin: 0.25rem;
  background: rgba(255, 255, 255, 0.95);
  color: #554769;
  text-decoration: none;
  font-size: 0.9rem;
  font-weight: 500;
  transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
  text-align: center;
  border-radius: 16px;
  border: 1px solid rgba(140, 122, 230, 0.15);
  box-shadow: 
    0 2px 4px rgba(140, 122, 230, 0.05),
    0 0 0 1px rgba(140, 122, 230, 0.05);
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

.custom-button::before {
  content: '';
  position: absolute;
  inset: 0;
  background: linear-gradient(
    45deg,
    rgba(140, 122, 230, 0.15),
    rgba(155, 89, 182, 0.15)
  );
  opacity: 0;
  transition: opacity 0.4s ease;
}

.custom-button::after {
  content: '';
  position: absolute;
  inset: 0;
  background: radial-gradient(circle at center, rgba(140, 122, 230, 0.2) 0%, transparent 70%);
  opacity: 0;
  transition: all 0.4s ease;
  transform: scale(0.5);
}

.custom-button .material-symbols-rounded {
  font-size: 1.1rem;
  transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
  position: relative;
  z-index: 1;
  color: #6c5ce7;
}

.custom-button span {
  position: relative;
  z-index: 1;
}

.custom-button:hover {
  transform: translateY(-2px) scale(1.02);
  background: white;
  color: #6c5ce7;
  border-color: rgba(140, 122, 230, 0.3);
  box-shadow: 
    0 10px 20px -5px rgba(140, 122, 230, 0.2),
    0 5px 10px -5px rgba(140, 122, 230, 0.1);
}

.custom-button:hover::before {
  opacity: 1;
}

.custom-button:hover::after {
  opacity: 1;
  transform: scale(1);
}

.custom-button:hover .material-symbols-rounded {
  transform: scale(1.1);
  color: #6c5ce7;
}

.custom-button:active {
  transform: translateY(0) scale(0.98);
  transition-duration: 0.1s;
}

/* Ajustes responsivos */
@media (max-width: 768px) {
  .custom-button {
    padding: 0.6rem 1rem;
    font-size: 0.85rem;
  }

  .custom-button .material-symbols-rounded {
    font-size: 1rem;
  }
}

@media (max-width: 480px) {
  .custom-button {
    padding: 0.75rem;
    font-size: 0.8rem;
    background: white;
    border: 1px solid rgba(108, 92, 231, 0.15);
    box-shadow: 0 2px 4px rgba(108, 92, 231, 0.05);
  }
}

@media (max-width: 1024px) {
  .filters-row {
    gap: 1.5rem;
    justify-content: center;
  }
}

@media (max-width: 768px) {
  .contenedor-filters {
    padding: 1rem;
    margin: 0.5rem;
  }

  .filters-row {
    flex-direction: column;
    gap: 1rem;
    width: 100%;
  }

  .filter {
    width: 100%;
    justify-content: space-between;
  }

  .dropdown {
    width: 60%;
  }
}

@media (max-width: 576px) {
  .contenedor-filters {
    padding: 1rem 2.5rem;
  }
}
