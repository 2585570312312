.navbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 70px;
  background: rgba(255, 255, 255, 0.95);
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
  z-index: 9999;
  border-bottom: 1px solid rgba(231, 231, 237, 0.8);
}

.App {
  padding-top: 70px;
  width: 100%;
  min-height: 100vh;
  position: relative;
}

.navbar-content {
  width: 100%;
  max-width: 1600px;
  margin: 0 auto;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 3rem;
  box-sizing: border-box;
}

.navbar-left {
  display: flex;
  align-items: center;
  flex: 0 1 auto;
  min-width: 200px;
}

.navbar-right {
  display: flex;
  align-items: center;
  gap: 2rem;
  flex: 0 1 auto;
  justify-content: flex-end;
  margin-left: auto;
}

.logo-link {
  display: flex;
  align-items: center;
  opacity: 0.9;
  transition: opacity 0.3s ease;
}

.navbar-logo {
  height: 35px;
  width: auto;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  filter: brightness(1.02);
  margin-right: 1rem;
}

.logo-link:hover {
  opacity: 1;
}

.navbar-logo:hover {
  transform: scale(1.03);
  filter: brightness(1.05);
}

.nav-link {
  display: flex;
  align-items: center;
  gap: 0.6rem;
  text-decoration: none;
  color: #4a4a4a;
  font-weight: 400;
  font-size: 0.95rem;
  letter-spacing: 0.3px;
  padding: 0.6rem 1.2rem;
  border-radius: 12px;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  border: 1px solid transparent;
}

.nav-link:hover {
  background: rgba(246, 246, 252, 0.8);
  color: #7667e3;
  border: 1px solid rgba(118, 103, 227, 0.1);
}

.create-button {
  display: inline-flex;
  align-items: center;
  gap: 8px;
  padding: 10px 20px;
  background: linear-gradient(45deg, #8c7ae6, #9b59b6);
  color: white;
  border-radius: 50px;
  font-weight: 600;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
  box-shadow: 0 4px 15px rgba(140, 122, 230, 0.2);
}

.create-button:hover {
  transform: translateY(-1px);
  box-shadow: 0 4px 15px rgba(140, 122, 230, 0.2);
  background: linear-gradient(45deg, #7f6ee3, #8e44ad);
  color: white;
}

.create-button:active {
  transform: translateY(0);
  box-shadow: 0 4px 15px rgba(140, 122, 230, 0.2);
}

.create-button .material-symbols-rounded {
  font-size: 20px;
  transition: transform 0.3s ease;
  color: white;
}

.create-button:hover .material-symbols-rounded {
  transform: rotate(90deg);
}

.button-text-large,
.button-text-small {
  color: white;
  display: inline;
  margin-left: 4px;
}

.button-text-small {
  display: none;
}

@media (max-width: 768px) {
  .button-text-large {
    display: none;
  }
  
  .button-text-small {
    display: inline;
    margin-left: 4px;
  }
  
  .create-button {
    padding: 8px 16px;
  }
}

.create-button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
  background: rgba(206, 212, 218, 0.8);
  color: #6b7280;
  box-shadow: none;
  pointer-events: none;
}

.create-button:not(:disabled) {
  cursor: pointer;
}

.create-button:focus {
  outline: none;
  box-shadow: 
    0 0 0 2px white,
    0 0 0 4px rgba(108, 92, 231, 0.4);
}

/* Estado de carga */
.create-button.loading {
  cursor: wait;
  pointer-events: none;
}

.create-button.loading span,
.create-button.loading .material-symbols-rounded {
  opacity: 0.7;
}

/* Estado de éxito */
.create-button.success {
  background: linear-gradient(135deg, #10b981, #34d399);
  pointer-events: none;
}

/* Estado de error */
.create-button.error {
  background: linear-gradient(135deg, #ef4444, #f87171);
  pointer-events: none;
}

.material-symbols-rounded {
  font-size: 19px;
  opacity: 0.9;
}

@media screen and (max-width: 1200px) {
  .navbar {
    height: 65px;
  }

  .App {
    padding-top: 65px;
  }

  .navbar-content {
    padding: 0 2.5rem;
  }

  .navbar-right {
    gap: 1.8rem;
  }
}

@media screen and (max-width: 768px) {
  .navbar {
    height: 60px;
  }

  .App {
    padding-top: 60px;
  }

  .navbar-content {
    padding: 0 1.8rem;
  }

  .navbar-right {
    gap: 1.2rem;
  }

  .navbar-left {
    min-width: 130px;
  }

  .nav-link {
    padding: 0.45rem 0.9rem;
    font-size: 0.85rem;
  }

  .create-button {
    padding: 0.5rem 1rem;
  }
}

@media screen and (max-width: 500px) {
  .navbar {
    height: 55px;
  }

  .App {
    padding-top: 55px;
  }

  .navbar-content {
    padding: 0 1rem;
  }

  .navbar-right {
    gap: 0.8rem;
  }

  .navbar-left {
    min-width: 100px;
  }

  .navbar-logo {
    height: 25px;
    margin-right: 0.4rem;
  }

  .nav-link {
    padding: 0.4rem 0.7rem;
    font-size: 0.8rem;
  }

  .create-button {
    padding: 0.45rem 0.9rem;
  }
}

@media screen and (min-width: 1921px) {
  .navbar {
    height: 80px;
  }

  .App {
    padding-top: 80px;
  }

  .navbar-content {
    max-width: none;
    width: 95%;
    padding: 0 4rem;
  }

  .navbar-left {
    min-width: 250px;
    flex: 1;
  }

  .navbar-right {
    flex: 1;
    justify-content: flex-end;
    gap: 2rem;
  }

  .navbar-logo {
    height: 45px;
  }

  .nav-link {
    font-size: 1.2rem;
    padding: 0.8rem 1.6rem;
  }

  .create-button {
    padding: 0.85rem 1.7rem;
    font-size: 1.2rem;
  }

  .material-symbols-rounded {
    font-size: 24px;
  }

  .home-button {
    padding: 0.75rem;
  }

  .home-button .material-symbols-rounded {
    font-size: 26px;
  }
}

@media screen and (min-width: 2560px) {
  .navbar {
    height: 90px;
  }

  .App {
    padding-top: 90px;
  }

  .navbar-content {
    width: 96%;
    padding: 0 5rem;
  }

  .navbar-left {
    min-width: 300px;
    flex: 1.5;
  }

  .navbar-right {
    flex: 2;
    gap: 3rem;
  }

  .navbar-logo {
    height: 50px;
    margin-right: 2rem;
  }

  .nav-link {
    font-size: 1.3rem;
    padding: 0.9rem 1.8rem;
  }

  .create-button {
    padding: 0.95rem 2rem;
    font-size: 1.3rem;
  }

  .material-symbols-rounded {
    font-size: 26px;
  }

  .home-button {
    padding: 0.85rem;
  }

  .home-button .material-symbols-rounded {
    font-size: 28px;
  }
}

@media screen and (min-width: 3840px) {
  .navbar {
    height: 100px;
  }

  .App {
    padding-top: 100px;
  }

  .navbar-content {
    width: 98%;
    padding: 0 6rem;
  }

  .navbar-left {
    min-width: 400px;
    flex: 2;
  }

  .navbar-right {
    flex: 3;
    gap: 4rem;
    padding-left: 2rem;
  }

  .navbar-logo {
    height: 60px;
    margin-right: 3rem;
  }

  .nav-link {
    font-size: 1.5rem;
    padding: 1rem 2rem;
    border-radius: 16px;
  }

  .create-button {
    padding: 1.1rem 2.4rem;
    font-size: 1.5rem;
    border-radius: 12px;
    margin-left: 2rem;
  }

  .material-symbols-rounded {
    font-size: 30px;
  }

  .home-button {
    padding: 1rem;
    border-radius: 12px;
  }

  .home-button .material-symbols-rounded {
    font-size: 32px;
  }
}

.home-button {
  padding: 0.65rem;
  border-radius: 8px;
  background: linear-gradient(135deg, rgba(139, 127, 249, 0.08), rgba(157, 145, 255, 0.08));
  transition: all 0.25s cubic-bezier(0.4, 0, 0.2, 1);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
}

.home-button::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(135deg, rgba(139, 127, 249, 0.15), rgba(157, 145, 255, 0.15));
  opacity: 0;
  transition: opacity 0.25s ease;
}

.home-button .material-symbols-rounded {
  font-size: 22px;
  color: #8b7ff9;
  opacity: 0.85;
  font-variation-settings: 'FILL' 0, 'wght' 400;
  transition: all 0.25s cubic-bezier(0.4, 0, 0.2, 1);
  position: relative;
  z-index: 1;
}

.home-button:hover {
  transform: translateY(-1px);
  box-shadow: 0 2px 8px rgba(139, 127, 249, 0.15);
}

.home-button:hover::before {
  opacity: 1;
}

.home-button:hover .material-symbols-rounded {
  opacity: 1;
  font-variation-settings: 'FILL' 1, 'wght' 400;
  transform: scale(1.1);
  color: #7667e3;
}

.home-button:active {
  transform: translateY(0);
  box-shadow: 0 1px 4px rgba(139, 127, 249, 0.1);
}

.home-button:active .material-symbols-rounded {
  transform: scale(0.95);
}
