/* Estilos para el contenedor de plataformas */
.platforms-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
  gap: 1rem;
  margin-top: 1rem;
}

/* Estilos para cada checkbox de plataforma */
.platform-checkbox {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  padding: 0.875rem 1.25rem;
  background: rgba(255, 255, 255, 0.95);
  border: 2px solid rgba(140, 122, 230, 0.15);
  border-radius: 16px;
  cursor: pointer;
  transition: all 0.3s ease;
  overflow: hidden;
  flex-direction: row;
  justify-content: flex-start;
}

.platform-checkbox:hover {
  transform: translateY(-2px);
  border-color: rgba(140, 122, 230, 0.3);
  box-shadow: 
    0 8px 20px -5px rgba(140, 122, 230, 0.2),
    0 5px 10px -5px rgba(140, 122, 230, 0.1);
}

/* Estilos para el input checkbox */
.platform-checkbox input[type="checkbox"] {
  appearance: none;
  -webkit-appearance: none;
  width: 16px;
  height: 16px;
  border-radius: 4px;
  border: 2px solid #e6e0f0;
  background: white;
  cursor: pointer;
  position: relative;
  transition: all 0.2s ease;
}

.platform-checkbox input[type="checkbox"]:checked {
  background: #8c7ae6;
  border-color: #8c7ae6;
}

.platform-checkbox input[type="checkbox"]:checked::after {
  content: '✓';
  position: absolute;
  color: white;
  font-size: 11px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* Estilos para los iconos de plataforma */
.platform-icon {
  font-size: 1.2rem;
  color: #554769;
  transition: all 0.3s ease;
  position: relative;
  z-index: 1;
}

.platform-name {
  font-size: 0.95rem;
  color: #554769;
  font-weight: 500;
  transition: all 0.3s ease;
  position: relative;
  z-index: 1;
  text-align: left;
}

.platform-checkbox:hover .platform-icon {
  transform: scale(1.1);
  color: #6c5ce7;
}

.platform-checkbox input[type="checkbox"]:checked + .platform-icon {
  color: #6c5ce7;
}

.platform-checkbox input[type="checkbox"]:checked ~ .platform-name {
  color: #6c5ce7;
  font-weight: 600;
}

/* Estilos responsivos */
@media (max-width: 768px) {
  .platforms-grid {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 0.75rem;
  }
  
  .platform-checkbox {
    padding: 0.75rem 1rem;
  }
  
  .platform-icon {
    font-size: 1.1rem;
  }

  .platform-name {
    font-size: 0.9rem;
  }
}

@media (max-width: 480px) {
  .platforms-grid {
    grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
    gap: 0.75rem;
  }

  .platform-checkbox {
    padding: 0.75rem;
    border-radius: 8px;
    min-height: 44px;
    flex-direction: row;
    align-items: center;
    gap: 0.75rem;
  }

  .platform-icon {
    font-size: 1.1rem;
    min-width: 24px;
  }

  .platform-name {
    font-size: 0.85rem;
    margin-left: 0.5rem;
    line-height: 1.2;
    white-space: normal;
    word-wrap: break-word;
    flex: 1;
    display: block;
  }
}

/* Ajustes específicos para dispositivos muy pequeños */
@media (max-width: 360px) {
  .platforms-grid {
    grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
    gap: 0.5rem;
  }

  .platform-checkbox {
    padding: 0.625rem;
    min-height: 40px;
    gap: 0.5rem;
  }

  .platform-icon {
    font-size: 1rem;
    min-width: 20px;
  }

  .platform-name {
    font-size: 0.8rem;
    margin-left: 0.35rem;
    display: block;
    flex: 1;
  }
} 
