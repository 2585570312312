.footer {
  background: #ffffff;
  padding: 1rem;
  border-top: 1px solid #f0f2f5;
  text-align: center;
}

.footer-content {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.3rem;
}

.footer-text {
  color: #636e72;
  font-size: 0.95rem;
  line-height: 1.4;
  margin: 0;
}

.footer-highlight {
  color: #6c5ce7;
  font-weight: 500;
}

.footer-links {
  display: flex;
  gap: 2rem;
  margin-top: 1rem;
}

.footer-link {
  color: #2d3436;
  text-decoration: none;
  transition: color 0.3s ease;
}

.footer-link:hover {
  color: #6c5ce7;
}

@media (max-width: 768px) {
  .footer {
    padding: 0.8rem;
  }
  
  .footer-content {
    gap: 0.2rem;
  }
  
  .footer-text {
    font-size: 0.9rem;
  }
  
  .footer-links {
    gap: 1rem;
    flex-wrap: wrap;
    justify-content: center;
  }
}
