.loader-container {
  width: 100%;
  min-height: 300px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.95);
  z-index: 100;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 1.5rem;
  border-radius: 12px;
  gap: 1.5rem;
}

.loader-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.75rem;
  margin-top: 1rem;
}

.loader-container p {
  font-size: 1rem;
  color: #2d3436;
  margin: 0;
  opacity: 0.8;
}

.loading-skeleton {
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
}

.loader-content h1 {
  color: #2d3436;
  font-size: 1.5rem;
  margin: 0;
}

.img-loading {
  width: 150px;
  height: 150px;
  object-fit: contain;
}
