.platform-games {
  min-height: 100vh;
  background: #ffffff;
  color: #2d3436;
  padding: 20px;
}

.platform-content {
  max-width: 1400px;
  margin: 0 auto;
  padding: 2rem;
  width: 95%;
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 80px);
}

.platform-header {
  margin: 1rem 0 3rem;
  text-align: center;
  position: relative;
  padding: 30px 20px;
  background: #ffffff;
}

.platform-title {
  font-size: 2.8rem;
  font-weight: 800;
  color: #2d3436;
  margin-bottom: 20px;
  position: relative;
  display: inline-block;
}

.platform-title::after {
  content: '';
  position: absolute;
  bottom: -10px;
  left: 0;
  width: 100%;
  height: 4px;
  background: linear-gradient(90deg, #6c5ce7 0%, #a29bfe 100%);
  border-radius: 2px;
  transform-origin: left;
  animation: lineGrow 0.6s ease-out forwards;
}

.platform-stats {
  margin: 25px 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.games-count {
  font-size: 1.1rem;
  color: #6c5ce7;
  font-weight: 500;
}

.platform-cards-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  flex-grow: 1;
  padding: 20px 0;
}

.platform-cards-container > div:first-child {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 2rem;
  justify-items: center;
  margin-bottom: 2rem;
}

.no-games-found {
  text-align: center;
  padding: 3rem 2rem;
  background: #ffffff;
  border: 1px solid #eee;
  border-radius: 12px;
  margin: 2rem auto;
  max-width: 500px;
}

.no-games-found h2 {
  font-size: 1.8rem;
  margin-bottom: 1rem;
  color: #333;
  font-weight: 600;
}

.no-games-found p {
  font-size: 1rem;
  color: #666;
  line-height: 1.5;
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 80px);
  width: 100%;
  background-color: #ffffff;
}

@keyframes lineGrow {
  from {
    transform: scaleX(0);
  }
  to {
    transform: scaleX(1);
  }
}

/* Responsive */
@media (max-width: 768px) {
  .platform-content {
    padding: 1rem;
  }
  
  .platform-header {
    margin: 0.5rem 0 2rem;
    padding: 20px 15px;
  }

  .platform-title {
    font-size: 2rem;
    margin-bottom: 15px;
  }

  .platform-title::after {
    bottom: -8px;
    height: 3px;
  }

  .platform-stats {
    gap: 10px;
  }

  .games-count {
    font-size: 0.95rem;
  }
}

@media (min-width: 1024px) {
  .platform-cards-container > div:first-child {
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
  }
}

@media (min-width: 1440px) {
  .platform-cards-container > div:first-child {
    grid-template-columns: repeat(4, 1fr);
  }
} 
