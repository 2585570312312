.social-container {
  display: flex;
  gap: 1.2rem;
  align-items: center;
  margin: 0.8rem 0 0.3rem;
  justify-content: center;
  padding: 0.3rem;
}

.social-link {
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  padding: 0.4rem;
  border-radius: 50%;
}

.social-link:hover {
  transform: translateY(-2px);
}

.social-link:hover svg {
  stroke: #6c5ce7;
}

.icon-tabler {
  transition: all 0.3s ease;
}

/* Colores específicos para cada red social en hover */
.github:hover svg {
  stroke: #333;
}

.twitter:hover svg {
  stroke: #1DA1F2;
}

.linkedin:hover svg {
  stroke: #0077b5;
}

@media (max-width: 768px) {
  .social-container {
    gap: 1rem;
    margin: 0.6rem 0 0.2rem;
    padding: 0.3rem;
  }
  
  .social-link {
    padding: 0.3rem;
  }
  
  .icon-tabler {
    width: 25px;
    height: 25px;
  }
}
