.contenedor-create {
  min-height: 100vh;
  padding: 2rem;
  background: linear-gradient(135deg, #ffffff, #fafaff);
  display: flex;
  justify-content: center;
  align-items: flex-start;
  position: relative;
  overflow: hidden;
}

.contenedor-create::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 500px;
  background: linear-gradient(135deg, rgba(108, 92, 231, 0.015), rgba(162, 155, 254, 0.015));
  opacity: 0.5;
  transform: skewY(-8deg);
  transform-origin: top left;
}

.contenedor-create2 {
  max-width: 1200px;
  width: 100%;
  margin: 2rem auto;
  padding: 2.5rem;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 40px;
  box-shadow: 
    0 20px 60px -20px rgba(140, 122, 230, 0.05),
    0 0 0 1px rgba(140, 122, 230, 0.03);
  position: relative;
  z-index: 1;
  backdrop-filter: blur(30px);
  border: 1px solid rgba(255, 255, 255, 0.9);
}

.header-content {
  margin-bottom: 2.5rem;
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  width: 100%;
}

.input-title {
  font-size: 2.2rem;
  font-weight: 700;
  margin-bottom: 1.5rem;
  text-align: left;
  letter-spacing: -0.025em;
  line-height: 1.2;
  color: #443a7b;
  position: relative;
  padding-bottom: 1rem;
}

.input-title::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 80px;
  height: 3px;
  background: linear-gradient(90deg, rgba(108, 92, 231, 0.3), rgba(162, 155, 254, 0.3));
  border-radius: 4px;
}

.input-subtitle {
  font-size: 1.1rem;
  color: #77648b;
  line-height: 1.6;
  font-weight: 400;
  opacity: 0.9;
  max-width: 100%;
  text-align: left;
  letter-spacing: 0.01em;
  margin: 0;
  padding-right: 1rem;
}

.required-info {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-top: 0.5rem;
}

.required-mark {
  color: #e74c3c;
  font-weight: 600;
  font-size: 1.1rem;
}

.input-subtitle-note {
  font-size: 0.95rem;
  color: #77648b;
  line-height: 1.5;
  font-weight: 500;
  opacity: 0.95;
  margin: 0;
}

.form-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1.5rem;
  margin-top: 1rem;
}

@media (min-width: 768px) {
  .form-grid {
    grid-template-columns: repeat(2, 1fr);
  }

  .full-width,
  .button-container {
    grid-column: 1 / -1;
  }
}

.form-group {
  position: relative;
  margin-bottom: 1.5rem;
  transition: all 0.3s ease;
  opacity: 0;
  transform: translateY(10px);
  animation: fadeInUp 0.5s ease forwards;
}

@keyframes fadeInUp {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.form-group:hover .input-label {
  color: #6c5ce7;
  transform: translateY(-1px);
}

.input-label {
  display: block;
  color: #554769;
  font-size: 0.9rem;
  font-weight: 500;
  margin-bottom: 0.4rem;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  gap: 0.4rem;
}

.input-label::before {
  content: '';
  display: inline-block;
  width: 4px;
  height: 4px;
  background: #6c5ce7;
  border-radius: 50%;
  opacity: 0.7;
}

.barra {
  width: 100%;
  padding: 0.875rem 1.125rem;
  border: 2px solid #e6e0f0;
  border-radius: 12px;
  background-color: #ffffff;
  transition: all 0.3s ease;
  font-size: 0.95rem;
  color: #2d3436;
  box-shadow: 
    0 2px 4px rgba(140, 122, 230, 0.03),
    0 0 0 1px rgba(140, 122, 230, 0.03);
}

.barra:focus {
  outline: none;
  border-color: #6c5ce7;
  box-shadow: 
    0 4px 12px rgba(108, 92, 231, 0.1),
    0 0 0 3px rgba(108, 92, 231, 0.1);
  transform: translateY(-1px);
}

.barra::placeholder {
  color: #a0a0a0;
  opacity: 0.7;
}

.barra-description {
  min-height: 150px;
  max-height: 300px;
  width: 100%;
  line-height: 1.5;
  resize: vertical;
  padding: 1rem;
  font-size: 0.9rem;
  color: #2d3436;
  background-color: #ffffff;
  border: 2px solid #e6e0f0;
  border-radius: 12px;
  transition: all 0.3s ease;
  font-family: inherit;
  box-shadow: 
    0 2px 4px rgba(140, 122, 230, 0.03),
    0 0 0 1px rgba(140, 122, 230, 0.03);
}

.barra-description:focus {
  outline: none;
  border-color: #6c5ce7;
  box-shadow: 
    0 4px 12px rgba(108, 92, 231, 0.1),
    0 0 0 3px rgba(108, 92, 231, 0.1);
  transform: translateY(-1px);
}

.barra-description::placeholder {
  color: #a0a0a0;
  opacity: 0.7;
}

.description-container {
  position: relative;
  width: 100%;
  margin-bottom: 2rem;
}

.description-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0.75rem;
  padding: 0 0.5rem;
}

.character-count {
  font-size: 0.85rem;
  color: #77648b;
  transition: all 0.3s ease;
}

.character-count.warning {
  color: #e17055;
}

.character-count.error {
  color: #d63031;
}

.description-status {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 0.85rem;
  color: #10b981;
}

.platforms-grid {
  margin-top: 1rem;
}

.genres-container {
  display: flex;
  flex-wrap: wrap;
  gap: 0.75rem;
  margin-top: 1rem;
}

.button-genres {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem 1.25rem;
  background: rgba(255, 255, 255, 0.95);
  color: #554769;
  border: 1px solid rgba(140, 122, 230, 0.15);
  border-radius: 16px;
  font-size: 0.95rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
  position: relative;
  overflow: hidden;
}

.button-genres::before {
  content: '';
  position: absolute;
  inset: 0;
  background: linear-gradient(
    45deg,
    rgba(140, 122, 230, 0.15),
    rgba(155, 89, 182, 0.15)
  );
  opacity: 0;
  transition: opacity 0.4s ease;
}

.button-genres::after {
  content: '';
  position: absolute;
  inset: 0;
  background: radial-gradient(circle at center, rgba(140, 122, 230, 0.2) 0%, transparent 70%);
  opacity: 0;
  transition: all 0.4s ease;
  transform: scale(0.5);
}

.button-genres:hover {
  transform: translateY(-2px);
  background: white;
  color: #6c5ce7;
  border-color: rgba(140, 122, 230, 0.3);
  box-shadow: 
    0 8px 20px -5px rgba(140, 122, 230, 0.2),
    0 5px 10px -5px rgba(140, 122, 230, 0.1);
}

.button-genres:hover::before {
  opacity: 1;
}

.button-genres:hover::after {
  opacity: 1;
  transform: scale(1);
}

.button-genres.selected {
  background: linear-gradient(135deg, #6c5ce7, #8c7ae6);
  color: white;
  border-color: transparent;
  font-weight: 600;
  box-shadow: 
    0 8px 20px -4px rgba(140, 122, 230, 0.4),
    0 6px 12px -6px rgba(140, 122, 230, 0.3);
}

.button-genres.selected::before {
  opacity: 1;
  background: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.1),
    rgba(255, 255, 255, 0.05)
  );
}

.button-genres.selected::after {
  opacity: 1;
  transform: scale(1);
  background: radial-gradient(circle at center, rgba(255, 255, 255, 0.2) 0%, transparent 70%);
}

/* Ajustes responsivos */
@media (max-width: 768px) {
  .platforms-grid {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 0.75rem;
  }

  .platform-checkbox {
    padding: 0.75rem 1rem;
  }

  .platform-icon {
    font-size: 1.1rem;
  }

  .platform-name {
    font-size: 0.9rem;
  }

  .button-genres {
    padding: 0.75rem 1rem;
    font-size: 0.9rem;
  }
}

@media (max-width: 480px) {
  .platforms-grid {
    grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
    gap: 0.5rem;
  }

  .platform-checkbox {
    padding: 0.5rem 0.75rem;
    border-radius: 8px;
    min-height: 40px;
    flex-direction: row;
    align-items: center;
  }

  .platform-icon {
    font-size: 1rem;
    min-width: 20px;
  }

  .platform-name {
    font-size: 0.8rem;
    margin-left: 0.5rem;
    line-height: 1.2;
    white-space: normal;
    word-wrap: break-word;
  }

  .form-navigation {
    margin-top: 1.5rem;
    padding-top: 1rem;
    gap: 0.75rem;
    flex-direction: row;
    justify-content: space-between;
  }

  .nav-button {
    padding: 0.625rem 1rem;
    font-size: 0.8rem;
    min-width: 100px;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    white-space: nowrap;
  }

  .button-icon {
    display: inline-flex;
    font-size: 1rem;
  }
}

.input-forgot {
  color: #dc2626;
  font-size: 0.8rem;
  margin-top: 0.5rem;
  padding-left: 0.5rem;
  border-left: 2px solid #dc2626;
  animation: slideIn 0.3s ease;
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateX(-10px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.form-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  padding: 0.75rem 1.25rem;
  margin: 0.25rem;
  background: linear-gradient(45deg, #8c7ae6, #9b59b6);
  color: white;
  text-decoration: none;
  font-size: 0.9rem;
  font-weight: 500;
  transition: all 0.3s ease-in-out;
  text-align: center;
  border-radius: 14px;
  border: none;
  box-shadow: 0 4px 15px rgba(140, 122, 230, 0.2);
  position: relative;
  overflow: hidden;
  cursor: pointer;
  width: auto;
  min-width: 150px;
  pointer-events: auto;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.form-button:hover:not(:disabled) {
  transform: translateY(-1px);
  background: linear-gradient(45deg, #7f6ee3, #8e44ad);
  box-shadow: 0 4px 15px rgba(140, 122, 230, 0.2);
}

.form-button:active:not(:disabled) {
  transform: translateY(0);
  box-shadow: 0 4px 15px rgba(140, 122, 230, 0.2);
}

.form-button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
  background: rgba(206, 212, 218, 0.8);
  border: none;
  color: #6b7280;
  box-shadow: none;
  pointer-events: none;
}

.form-button:not(:disabled) {
  cursor: pointer;
}

.form-button:focus {
  outline: none;
  box-shadow: 
    0 0 0 2px white,
    0 0 0 4px rgba(108, 92, 231, 0.4);
}

.form-button .button-content {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  width: 100%;
  pointer-events: none;
}

.form-button .spinner {
  width: 18px;
  height: 18px;
  border: 2px solid rgba(255, 255, 255, 0.3);
  border-top-color: white;
  border-radius: 50%;
  animation: spin 0.8s linear infinite;
  pointer-events: none;
}

/* Estado de carga */
.form-button.loading {
  cursor: wait;
  pointer-events: none;
}

.form-button.loading .button-content {
  opacity: 0.7;
}

/* Estado de éxito */
.form-button.success {
  background: linear-gradient(135deg, #10b981, #34d399);
  pointer-events: none;
}

/* Estado de error */
.form-button.error {
  background: linear-gradient(135deg, #ef4444, #f87171);
  pointer-events: none;
}

/* Ajustes responsivos */
@media (max-width: 768px) {
  .form-button {
    padding: 0.6rem 1rem;
    font-size: 0.85rem;
  }
}

@media (max-width: 480px) {
  .form-button {
    padding: 0.5rem 0.75rem;
    font-size: 0.75rem;
    min-width: 100px;
    border-radius: 6px;
  }
}

.required-text {
  color: #77648b;
  font-size: 0.75rem;
  text-align: left;
  margin-top: 5rem;
}

fieldset {
  border: none;
  padding: 0;
  margin: 1.25rem 0;
}

legend {
  color: #77648b;
  font-size: 0.875rem;
  font-weight: 500;
  margin-bottom: 0.375rem;
}

.button-container {
  grid-column: 1 / -1;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.image-upload-container {
  position: relative;
  margin-top: 1rem;
  padding: 1.5rem;
  border: 2px dashed rgba(140, 122, 230, 0.2);
  border-radius: 16px;
  transition: all 0.3s ease;
  background: rgba(248, 249, 250, 0.5);
}

.image-upload-container:hover {
  border-color: rgba(140, 122, 230, 0.4);
  background: rgba(248, 249, 250, 0.8);
}

.image-preview-container {
  margin-top: 1.5rem;
  border-radius: 16px;
  overflow: hidden;
  box-shadow: 
    0 4px 12px rgba(140, 122, 230, 0.1),
    0 0 0 1px rgba(140, 122, 230, 0.1);
  position: relative;
  background: white;
  transition: all 0.3s ease;
}

.image-preview-container:hover {
  transform: translateY(-2px);
  box-shadow: 
    0 8px 20px rgba(140, 122, 230, 0.15),
    0 0 0 1px rgba(140, 122, 230, 0.15);
}

.delete-image-button {
  position: absolute;
  top: 8px;
  right: 8px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: rgba(220, 38, 38, 0.9);
  color: white;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 18px;
  transition: all 0.2s ease;
  padding: 0;
  line-height: 1;
}

.delete-image-button:hover {
  background-color: rgb(220, 38, 38);
  transform: scale(1.1);
}

.image-preview {
  width: 100%;
  height: 200px;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.image-preview:hover {
  transform: scale(1.05);
}

.additional-images-preview {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
  gap: 1.25rem;
  margin-top: 1.5rem;
}

.additional-image-container {
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 
    0 4px 12px rgba(140, 122, 230, 0.1),
    0 0 0 1px rgba(140, 122, 230, 0.1);
  position: relative;
  background: white;
  transition: all 0.3s ease;
  aspect-ratio: 16/9;
}

.additional-image-container:hover {
  transform: translateY(-2px) scale(1.02);
  box-shadow: 
    0 8px 20px rgba(140, 122, 230, 0.15),
    0 0 0 1px rgba(140, 122, 230, 0.15);
}

.additional-image-preview {
  width: 100%;
  height: 100px;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.additional-image-preview:hover {
  transform: scale(1.05);
}

.progress-bar {
  width: 100%;
  height: 6px;
  background: rgba(140, 122, 230, 0.1);
  border-radius: 8px;
  margin: 2.5rem 0;
  position: relative;
  overflow: hidden;
}

.progress-fill {
  height: 100%;
  background: linear-gradient(
    90deg,
    #6c5ce7,
    #a29bfe
  );
  transition: width 0.5s cubic-bezier(0.4, 0, 0.2, 1);
  position: relative;
  overflow: hidden;
}

.progress-fill::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    90deg,
    transparent,
    rgba(255, 255, 255, 0.3),
    transparent
  );
  animation: shimmer 2s infinite;
}

@keyframes shimmer {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}

.progress-text {
  position: absolute;
  right: 0;
  top: -25px;
  font-size: 0.875rem;
  color: #554769;
  font-weight: 500;
  background: rgba(255, 255, 255, 0.9);
  padding: 0.25rem 0.75rem;
  border-radius: 12px;
  box-shadow: 
    0 2px 4px rgba(140, 122, 230, 0.1),
    0 0 0 1px rgba(140, 122, 230, 0.1);
}

.form-steps {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
  gap: 1.5rem;
  position: relative;
  padding: 0.5rem;
  background: rgba(255, 255, 255, 0.6);
  border-radius: 30px;
  box-shadow: 
    0 4px 20px -8px rgba(140, 122, 230, 0.05),
    0 0 0 1px rgba(140, 122, 230, 0.02);
  backdrop-filter: blur(20px);
}

.step-button {
  flex: 1;
  padding: 1rem 1.5rem;
  background: transparent;
  color: #554769;
  border: none;
  border-radius: 16px;
  cursor: pointer;
  font-weight: 500;
  font-size: 0.95rem;
  transition: all 0.3s ease-in-out;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.75rem;
  white-space: normal;
  text-align: center;
  line-height: 1.2;
}

.step-button.active {
  background: linear-gradient(45deg, #8c7ae6, #9b59b6);
  color: white;
  box-shadow: 0 4px 15px rgba(140, 122, 230, 0.2);
}

.step-button:hover:not(.active) {
  background: linear-gradient(45deg, #8c7ae6, #9b59b6);
  color: white;
  transform: translateY(-1px);
  box-shadow: 0 4px 15px rgba(140, 122, 230, 0.2);
}

.step-button:active:not(.active) {
  transform: translateY(0);
}

.step-button .step-icon {
  font-size: 1.2rem;
  opacity: 0.9;
}

.step-text {
  display: inline-block;
  white-space: normal;
  word-wrap: break-word;
}

.nav-text {
  display: inline-block;
  white-space: nowrap;
}

/* Ajustes responsivos */
@media (max-width: 768px) {
  .form-steps {
    flex-direction: column;
    padding: 0.75rem;
    gap: 0.75rem;
  }

  .step-button {
    padding: 0.875rem;
    font-size: 0.9rem;
  }

  .form-group {
    margin-bottom: 1.25rem;
  }

  .input-label {
    font-size: 0.9rem;
  }

  .barra {
    padding: 0.75rem 1rem;
    font-size: 0.9rem;
  }

  .image-upload-container {
    padding: 1rem;
  }

  .additional-images-preview {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 1rem;
  }
}

@media (max-width: 480px) {
  .form-steps {
    padding: 0.375rem;
    gap: 0.375rem;
    border-radius: 12px;
    flex-direction: column;
  }

  .step-button {
    width: 100%;
    padding: 0.5rem;
    font-size: 0.75rem;
    border-radius: 8px;
    white-space: normal;
    text-align: center;
    min-height: 40px;
  }

  .step-text {
    font-size: 0.75rem;
    line-height: 1.2;
    display: block;
    text-align: center;
    white-space: normal;
  }

  .form-navigation {
    margin-top: 1.5rem;
    padding-top: 1rem;
    gap: 0.75rem;
  }

  .nav-button {
    padding: 0.625rem 1rem;
    font-size: 0.8rem;
    min-width: 100px;
    flex: 0 0 auto;
    white-space: nowrap;
  }

  .button-icon {
    font-size: 0.85rem;
  }

  .form-button {
    padding: 0.5rem 0.75rem;
    font-size: 0.75rem;
    min-width: 100px;
    border-radius: 6px;
  }
}

@media (max-width: 360px) {
  .form-navigation {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 0.5rem;
    margin-top: 1rem;
    padding-top: 0.75rem;
    width: 100%;
  }

  .nav-button {
    padding: 0.5rem 0.75rem;
    font-size: 0.75rem;
    min-width: 80px;
    flex: 0 0 auto;
    white-space: nowrap;
  }
}

.input-container {
  position: relative;
}

.input-check {
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  color: #10b981;
  font-size: 1.2rem;
}

.rating-container {
  display: flex;
  gap: 0.5rem;
  margin-top: 0.5rem;
}

.star-button {
  background: none;
  border: none;
  font-size: 1.5rem;
  color: #d1d5db;
  cursor: pointer;
  transition: all 0.2s ease;
  padding: 0;
}

.star-button.active {
  color: #fbbf24;
}

.star-button:hover {
  transform: scale(1.2);
}

.image-upload-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.form-navigation {
  grid-column: 1 / -1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1.5rem;
  margin-top: 3rem;
  padding-top: 2rem;
  border-top: 1px solid rgba(140, 122, 230, 0.15);
  width: 100%;
  position: relative;
}

.nav-button {
  padding: 0.875rem 1.75rem;
  background: transparent;
  color: #6c5ce7;
  border: none;
  font-size: 0.95rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.75rem;
  min-width: 140px;
  position: relative;
}

/* Ajustes responsivos */
@media (max-width: 768px) {
  .form-navigation {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    margin-top: 2rem;
    padding-top: 1.5rem;
  }

  .nav-button {
    padding: 0.75rem;
    font-size: 0.9rem;
    min-width: 120px;
  }
}

@media (max-width: 480px) {
  .form-navigation {
    display: grid;
    grid-template-columns: auto auto;
    justify-content: space-between;
    gap: 0.75rem;
    margin-top: 1.5rem;
    padding-top: 1rem;
    width: 100%;
  }

  .nav-button {
    padding: 0.625rem 1rem;
    font-size: 0.8rem;
    min-width: 100px;
    white-space: nowrap;
  }

  .nav-button:first-child {
    justify-self: start;
  }

  .nav-button:last-child {
    justify-self: end;
  }
}

@media (max-width: 360px) {
  .form-navigation {
    display: grid;
    grid-template-columns: auto auto;
    justify-content: space-between;
    gap: 0.5rem;
    margin-top: 1rem;
    padding-top: 0.75rem;
    width: 100%;
  }

  .nav-button {
    padding: 0.5rem 0.75rem;
    font-size: 0.75rem;
    min-width: 80px;
    white-space: nowrap;
  }

  .nav-button:first-child {
    justify-self: start;
  }

  .nav-button:last-child {
    justify-self: end;
  }
}

.nav-button::before,
.nav-button::after {
  display: none;
}

.nav-button:hover {
  transform: translateX(-3px);
  color: #8c7ae6;
  background: transparent;
  box-shadow: none;
}

.nav-button:hover[type="button"]:last-child {
  transform: translateX(3px);
}

.nav-button:active {
  transform: translateY(0);
  box-shadow: none;
}

.button-icon {
  font-size: 1.2rem;
  opacity: 0.95;
  transition: transform 0.3s ease;
  color: #6c5ce7;
}

.nav-button:hover .button-icon {
  transform: translateX(-3px);
  opacity: 1;
  color: #8c7ae6;
}

.nav-button:hover .button-icon:last-child {
  transform: translateX(3px);
}

.nav-button span {
  color: #6c5ce7;
  position: relative;
  z-index: 1;
}

.nav-button:hover span {
  color: #8c7ae6;
}

/* Ajustes responsivos */
@media (max-width: 768px) {
  .contenedor-create {
    padding: 1rem;
  }

  .contenedor-create2 {
    padding: 2rem;
    margin: 1rem auto;
  }

  .input-title {
    font-size: 1.8rem;
    margin-bottom: 0.5rem;
    padding-bottom: 0.75rem;
  }

  .input-subtitle {
    font-size: 0.95rem;
    margin-bottom: 1.5rem;
  }

  .form-navigation {
    flex-direction: column;
    gap: 1rem;
    margin-top: 2rem;
    padding-top: 1.5rem;
  }

  .nav-button {
    width: 100%;
    padding: 0.75rem;
    font-size: 0.9rem;
  }
}

@media (max-width: 480px) {
  .contenedor-create {
    padding: 0.5rem;
    min-height: calc(100vh - 55px);
  }

  .contenedor-create2 {
    padding: 1.25rem;
    margin: 0.5rem auto;
    border-radius: 16px;
  }

  .input-title {
    font-size: 1.4rem;
    margin-bottom: 0.75rem;
    padding-bottom: 0.5rem;
    line-height: 1.3;
  }

  .input-subtitle {
    font-size: 0.9rem;
    line-height: 1.5;
    margin-bottom: 1rem;
    text-align: justify;
    hyphens: auto;
    -webkit-hyphens: auto;
    -ms-hyphens: auto;
  }

  .required-info {
    margin-top: 0.25rem;
  }

  .input-subtitle-note {
    font-size: 0.85rem;
    text-align: justify;
    hyphens: auto;
    -webkit-hyphens: auto;
    -ms-hyphens: auto;
  }

  .form-steps {
    padding: 0.375rem;
    gap: 0.375rem;
    border-radius: 12px;
  }

  .step-button {
    padding: 0.625rem;
    font-size: 0.8rem;
    border-radius: 10px;
  }

  .form-grid {
    gap: 1rem;
  }

  .form-group {
    margin-bottom: 1rem;
  }

  .input-label {
    font-size: 0.8rem;
    margin-bottom: 0.25rem;
  }

  .barra {
    padding: 0.625rem 0.875rem;
    font-size: 0.85rem;
    border-radius: 8px;
  }

  .barra-description {
    min-height: 120px;
    padding: 0.75rem;
    font-size: 0.85rem;
    text-align: justify;
    hyphens: auto;
    -webkit-hyphens: auto;
    -ms-hyphens: auto;
  }

  .platforms-grid {
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    gap: 0.5rem;
  }

  .platform-checkbox {
    padding: 0.5rem 0.75rem;
    border-radius: 8px;
  }

  .platform-icon {
    font-size: 0.9rem;
  }

  .platform-name {
    font-size: 0.8rem;
  }

  .button-genres {
    padding: 0.5rem 0.75rem;
    font-size: 0.8rem;
    border-radius: 8px;
  }

  .genres-container {
    gap: 0.375rem;
  }

  .form-navigation {
    margin-top: 1.5rem;
    padding-top: 1rem;
    gap: 0.75rem;
  }

  .nav-button {
    padding: 0.625rem 1rem;
    font-size: 0.8rem;
    min-width: 100px;
  }

  .form-button {
    padding: 0.625rem 1rem;
    font-size: 0.8rem;
    min-width: 120px;
    border-radius: 8px;
  }

  .progress-bar {
    height: 4px;
    margin: 1.5rem 0;
  }

  .progress-text {
    font-size: 0.75rem;
    padding: 0.25rem 0.5rem;
    top: -20px;
  }

  .image-upload-container {
    padding: 0.75rem;
    border-radius: 8px;
  }

  .image-preview {
    height: 150px;
  }

  .additional-images-preview {
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    gap: 0.5rem;
  }

  .additional-image-preview {
    height: 80px;
  }

  .delete-image-button {
    width: 20px;
    height: 20px;
    font-size: 14px;
    top: 4px;
    right: 4px;
  }
}

.input-help {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background: #e9ecef;
  color: #6b7280;
  font-size: 12px;
  margin-left: 8px;
  cursor: help;
  transition: all 0.2s ease;
}

.input-help:hover {
  background: #8c7ae6;
  color: white;
}

.tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  background: #1f2937;
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 6px;
  font-size: 0.875rem;
  z-index: 10;
  width: max-content;
  max-width: 300px;
  margin-top: 0.5rem;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
  animation: fadeIn 0.2s ease;
}

.tooltip::before {
  content: '';
  position: absolute;
  bottom: 100%;
  left: 1rem;
  border: 6px solid transparent;
  border-bottom-color: #1f2937;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(5px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.barra.error,
.barra-description.error {
  border-color: #dc2626;
  background-color: #fef2f2;
}

.button-content {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.spinner {
  width: 20px;
  height: 20px;
  border: 2px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: white;
  animation: spin 0.8s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

/* Mejoras en la accesibilidad */
@media (prefers-reduced-motion: reduce) {
  .spinner,
  .tooltip,
  .input-forgot,
  .progress-fill {
    animation: none;
  }
}

/* Mejoras para modo oscuro */
@media (prefers-color-scheme: dark) {
  .tooltip {
    background: #374151;
  }
  
  .tooltip::before {
    border-bottom-color: #374151;
  }
  
  .input-help {
    background: #374151;
    color: #9ca3af;
  }
}

/* Mejoras para dispositivos táctiles */
@media (hover: none) {
  .input-help {
    width: 24px;
    height: 24px;
    font-size: 14px;
  }
  
  .tooltip {
    position: fixed;
    bottom: 1rem;
    left: 1rem;
    right: 1rem;
    margin: 0;
    max-width: none;
  }
}
