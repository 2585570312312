.App {
  text-align: center;
  min-height: 100vh;
  width: 100%;
  position: relative;
  padding-top: 70px;
  box-sizing: border-box;
  margin: 0;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
}
