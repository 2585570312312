.detail-page {
  min-height: 100vh;
  background: transparent;
  color: #2d3436;
  position: relative;
  margin-top: 0;
  padding-top: 0;
  overflow-x: hidden;
}

.detail-container {
  position: relative;
  width: 100%;
  margin: 0 auto;
  animation: fadeIn 0.5s ease-in-out;
  max-width: 1440px;
}

.detail-header {
  position: relative;
  width: 100%;
  height: 500px;
  overflow: hidden;
}

.detail-header::before {
  content: '';
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100vw;
  height: 100%;
  background: inherit;
  overflow: hidden;
}

.detail-main-image {
  position: relative;
  width: 100vw;
  height: 100%;
  object-fit: cover;
  object-position: center;
  left: 50%;
  transform: translateX(-50%);
}

@media (min-width: 769px) {
  .detail-header {
    height: 60vh;
    max-height: 700px;
    min-height: 500px;
  }

  .detail-main-image {
    object-position: center 30%;
  }
}

@media (min-width: 1440px) {
  .detail-header {
    height: 65vh;
    max-height: 900px;
  }

  .detail-main-image {
    object-position: center 25%;
  }

  .detail-container {
    max-width: 1920px;
  }
}

.detail-content {
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(255, 255, 255, 1));
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.detail-text-info {
  margin-top: 15%;
  padding: 2rem;
  text-align: justify;
}

.game-header {
  margin-bottom: 2rem;
}

.game-header h1 {
  font-size: 2.5em;
  margin-bottom: 1rem;
  color: #2d3436;
  text-align: left;
  margin: 0;
  display: inline-flex;
  align-items: center;
}

.star-rating-container {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  flex-direction: row !important;
  justify-content: flex-start;
  width: 100%;
}

.stars {
  display: flex;
  gap: 0.2rem;
  align-items: center;
}

.star {
  font-size: 1.2rem;
  transition: all 0.2s ease-in-out;
}

.star.filled {
  color: #ffd700;
  filter: drop-shadow(0 0 2px rgba(255, 215, 0, 0.3));
}

.star.half {
  color: #ffd700;
  filter: drop-shadow(0 0 2px rgba(255, 215, 0, 0.3));
}

.star.empty {
  color: #d1d1d1;
}

.detail-page .rating-number {
  font-size: 1rem;
  color: #1a1d1e;
  font-weight: 700;
  background: rgba(45, 52, 54, 0.12) !important;
  padding: 0.3rem 0.8rem;
  border-radius: 12px;
  backdrop-filter: blur(5px) !important;
  border: 1px solid rgba(45, 52, 54, 0.25) !important;
  box-shadow: 0 2px 4px rgba(45, 52, 54, 0.15) !important;
  transition: all 0.2s ease;
  letter-spacing: 0.02em;
}

.detail-page .rating-number:hover {
  background: rgba(45, 52, 54, 0.18) !important;
  transform: translateY(-1px);
  box-shadow: 0 4px 8px rgba(45, 52, 54, 0.25) !important;
}

.stars:hover .star {
  transform: scale(1.1);
}

.game-info-section {
  display: grid;
  gap: 2rem;
  margin-bottom: 2rem;
}

.description-container {
  padding: 0;
  max-width: 100%;
  margin: 0 auto;
}

.description-container h2 {
  margin-bottom: 1rem;
  color: #636e72;
  font-size: 0.75rem;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  opacity: 0.7;
  position: relative;
  display: inline-block;
  width: 100%;
  text-align: left;
}

.description-container h2::after {
  content: '';
  position: absolute;
  bottom: -3px;
  left: 50%;
  transform: translateX(-50%);
  width: 15px;
  height: 1px;
  background-color: #6c5ce7;
  opacity: 0.4;
}

.description-text {
  line-height: 1.7;
  color: #2d3436;
  font-size: 0.88rem;
  font-weight: 400;
  letter-spacing: 0.2px;
  opacity: 0.85;
  text-align: justify;
  hyphens: auto;
}

.description-text p {
  margin-bottom: 0.8rem;
  text-align: justify;
}

.description-text p:last-child {
  margin-bottom: 0;
}

.description-text br {
  display: block;
  content: "";
  margin-top: 0.4rem;
}

.metadata-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin: 1rem 0;
}

.metadata-item {
  flex: 1;
  min-width: 200px;
  padding: 0.75rem;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.esrb-container {
  flex: 1;
  min-width: 200px;
  padding: 0.75rem;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba(140, 122, 230, 0.15);
  border-radius: 16px;
  box-shadow: 
    0 2px 4px rgba(140, 122, 230, 0.05),
    0 0 0 1px rgba(140, 122, 230, 0.05);
}

.esrb-rating {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.75rem;
  padding: 0.75rem;
  color: #636e72;
  font-size: 0.9rem;
  width: 100%;
  background: transparent;
  border: none;
  box-shadow: none;
  pointer-events: none;
}

.esrb-rating svg {
  font-size: 1.2rem;
  color: #6c5ce7;
}

.metadata-item:has(.website-link) {
  transition: all 0.2s ease;
}

.metadata-item:has(.website-link):hover {
  transform: translateY(-1px);
  box-shadow: 0 2px 4px rgba(0,0,0,0.05);
}

.metadata-item:has(.esrb-rating) {
  transition: none;
}

.metadata-item:has(.esrb-rating):hover {
  transform: none;
  box-shadow: none;
}

.website-link {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.75rem;
  padding: 0.75rem;
  height: 100%;
  min-height: 64px;
  background: rgba(255, 255, 255, 0.95);
  color: #554769;
  text-decoration: none;
  font-size: 0.9rem;
  font-weight: 500;
  border-radius: 16px;
  border: 1px solid rgba(140, 122, 230, 0.15);
  box-shadow: 
    0 2px 4px rgba(140, 122, 230, 0.05),
    0 0 0 1px rgba(140, 122, 230, 0.05);
  transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
  position: relative;
  overflow: hidden;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  min-width: 200px;
  flex: 1;
}

.website-link::before {
  content: '';
  position: absolute;
  inset: 0;
  background: linear-gradient(
    45deg,
    rgba(140, 122, 230, 0.15),
    rgba(155, 89, 182, 0.15)
  );
  opacity: 0;
  transition: opacity 0.4s ease;
}

.website-link::after {
  content: '';
  position: absolute;
  inset: 0;
  background: radial-gradient(circle at center, rgba(140, 122, 230, 0.2) 0%, transparent 70%);
  opacity: 0;
  transition: all 0.4s ease;
  transform: scale(0.5);
}

.website-link:hover,
.website-link:active {
  transform: translateY(-2px) scale(1.05);
  background: white;
  color: #6c5ce7;
  border-color: rgba(108, 92, 231, 0.3);
  box-shadow: 
    0 10px 20px -5px rgba(140, 122, 230, 0.2),
    0 5px 10px -5px rgba(140, 122, 230, 0.1);
}

.website-link:hover::before {
  opacity: 1;
}

.website-link:hover::after {
  opacity: 1;
  transform: scale(1);
}

.website-link:hover svg,
.website-link:active svg {
  transform: scale(1.2);
  color: inherit;
}

.website-link svg {
  font-size: 1.1rem;
  transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
  position: relative;
  z-index: 1;
  color: #6c5ce7;
}

.website-link span {
  display: inline-block;
  position: relative;
  z-index: 1;
  font-size: 0.9rem;
  white-space: nowrap;
  color: inherit;
}

.credits-section {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1rem;
  margin: 2rem 0;
}

.developers, .publishers {
  padding: 1rem;
  background: transparent;
  border-radius: 4px;
  border-left: 2px solid #6c5ce7;
  transition: none;
}

.developers:hover, .publishers:hover {
  background: transparent;
  transform: none;
  box-shadow: none;
  border-left: 2px solid #6c5ce7;
}

.developers h3, .publishers h3 {
  margin-bottom: 0.75rem;
  font-size: 0.9rem;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  opacity: 0.7;
  color: #554769;
}

.developers p, .publishers p {
  color: #2d3436;
  line-height: 1.5;
  font-size: 0.95rem;
  font-weight: 400;
}

/* Estilos base para las plataformas */
.platforms-container {
  display: flex;
  flex-wrap: wrap;
  gap: 0.75rem;
  margin: 2rem auto;
  padding: 1rem;
  justify-content: center;
  align-items: center;
  max-width: 90%;
}

.platform {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem 1.25rem;
  background: rgba(255, 255, 255, 0.95);
  color: #554769;
  text-decoration: none;
  font-size: 0.9rem;
  font-weight: 500;
  border-radius: 16px;
  border: 1px solid rgba(140, 122, 230, 0.15);
  box-shadow: 
    0 2px 4px rgba(140, 122, 230, 0.05),
    0 0 0 1px rgba(140, 122, 230, 0.05);
  transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
  position: relative;
  overflow: hidden;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}

.platform:hover,
.platform:active {
  transform: translateY(-2px) scale(1.05);
  background: white;
  color: #6c5ce7;
  border-color: rgba(140, 122, 230, 0.3);
  box-shadow: 
    0 10px 20px -5px rgba(140, 122, 230, 0.2),
    0 5px 10px -5px rgba(140, 122, 230, 0.1);
}

.platform:hover svg,
.platform:active svg {
  transform: scale(1.2);
  color: inherit;
}

/* Colores específicos para cada plataforma en hover */
.platform[href*="playstation"]:hover,
.platform[href*="playstation"]:active { color: #006FCD; border-color: rgba(0, 111, 205, 0.3); }
.platform[href*="xbox"]:hover,
.platform[href*="xbox"]:active { color: #107C10; border-color: rgba(16, 124, 16, 0.3); }
.platform[href*="pc"]:hover,
.platform[href*="pc"]:active,
.platform[href*="windows"]:hover,
.platform[href*="windows"]:active { color: #00A4EF; border-color: rgba(0, 164, 239, 0.3); }
.platform[href*="nintendo"]:hover,
.platform[href*="nintendo"]:active { color: #E60012; border-color: rgba(230, 0, 18, 0.3); }
.platform[href*="linux"]:hover,
.platform[href*="linux"]:active { color: #FCC624; border-color: rgba(252, 198, 36, 0.3); }
.platform[href*="mac"]:hover,
.platform[href*="mac"]:active { color: #555555; border-color: rgba(85, 85, 85, 0.3); }
.platform[href*="android"]:hover,
.platform[href*="android"]:active { color: #3DDC84; border-color: rgba(61, 220, 132, 0.3); }
.platform[href*="ios"]:hover,
.platform[href*="ios"]:active { color: #000000; border-color: rgba(0, 0, 0, 0.3); }

.platform svg {
  font-size: 1.1rem;
  transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
  position: relative;
  z-index: 1;
}

.platform-name {
  display: inline-block;
  margin-left: 0.2rem;
  position: relative;
  z-index: 1;
  font-size: 0.9rem;
  white-space: nowrap;
}

/* Ajustes responsivos para tablets y móviles */
@media (max-width: 768px) {
  .detail-header {
    height: 50vh;
    min-height: 400px;
  }

  .detail-text-info {
    margin-top: 20%;
    padding: 1.5rem;
  }

  .game-header h1 {
    font-size: 2rem;
    line-height: 1.2;
  }

  .star-rating-container {
    margin-top: 0.5rem;
    flex-wrap: wrap;
  }

  .description-container {
    margin-top: 1.5rem;
  }

  .description-text {
    font-size: 0.9rem;
    line-height: 1.6;
  }

  .metadata-grid {
    gap: 0.75rem;
    margin: 0.75rem 0;
  }

  .website-link,
  .esrb-container {
    min-height: 52px;
    padding: 0.75rem 1rem;
  }
}

@media (max-width: 480px) {
  .detail-header {
    height: 45vh;
    min-height: 350px;
  }

  .detail-text-info {
    margin-top: 30%;
    padding: 1.25rem;
  }

  .game-header h1 {
    font-size: 1.75rem;
    line-height: 1.3;
  }

  .star-rating-container {
    margin-top: 0.75rem;
  }

  .star {
    font-size: 1rem;
  }

  .rating-number {
    font-size: 0.85rem !important;
    padding: 0.25rem 0.6rem !important;
  }

  .description-container {
    margin-top: 1.25rem;
  }

  .description-text {
    font-size: 0.85rem;
    line-height: 1.5;
  }

  .metadata-grid {
    gap: 0.5rem;
    margin: 0.5rem 0;
    flex-direction: column;
  }

  .metadata-item {
    min-width: 100% !important;
  }

  .website-link,
  .esrb-container {
    min-height: 48px;
    padding: 0.6rem 0.85rem;
    width: 100%;
  }

  .platforms-container {
    display: grid !important;
    grid-template-columns: repeat(3, 1fr) !important;
    gap: 0.5rem !important;
    padding: 0.75rem !important;
    margin: 0.75rem auto !important;
    width: 100% !important;
    max-width: 100% !important;
  }

  .platform {
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    justify-content: center !important;
    text-align: center !important;
    padding: 0.5rem !important;
    gap: 0.25rem !important;
    min-height: 36px !important;
    width: 100% !important;
    margin: 0 !important;
    transition: all 0.3s ease !important;
  }

  .platform svg {
    font-size: 1rem !important;
    margin: 0 !important;
  }

  .platform-name {
    font-size: 0.7rem !important;
    margin: 0 !important;
    padding: 0 !important;
    display: block !important;
    text-align: center !important;
    line-height: 1 !important;
    white-space: normal !important;
    max-width: 100% !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
  }

  .credits-section {
    display: flex !important;
    flex-direction: column !important;
    gap: 0.75rem !important;
    margin: 1.5rem 0 !important;
  }

  .developers, .publishers {
    padding: 0.75rem !important;
    border-radius: 12px !important;
    border-left: none !important;
    border: 1px solid rgba(140, 122, 230, 0.15) !important;
    background: rgba(255, 255, 255, 0.5) !important;
  }

  .developers h3, .publishers h3 {
    font-size: 0.75rem !important;
    margin-bottom: 0.5rem !important;
    color: #636e72 !important;
    letter-spacing: 1px !important;
  }

  .developers p, .publishers p {
    font-size: 0.85rem !important;
    line-height: 1.4 !important;
    margin: 0 !important;
  }
}

@media (max-width: 360px) {
  .detail-header {
    height: 40vh;
    min-height: 300px;
  }

  .detail-text-info {
    margin-top: 40%;
    padding: 1rem;
  }

  .game-header h1 {
    font-size: 1.5rem;
    line-height: 1.2;
  }

  .star-rating-container {
    margin-top: 0.5rem;
  }

  .star {
    font-size: 0.9rem;
  }

  .rating-number {
    font-size: 0.8rem !important;
    padding: 0.2rem 0.5rem !important;
  }

  .description-container {
    margin-top: 1rem;
  }

  .description-text {
    font-size: 0.8rem;
    line-height: 1.4;
  }

  .metadata-grid {
    gap: 0.4rem;
  }

  .website-link,
  .esrb-container {
    min-height: 42px;
    padding: 0.5rem 0.75rem;
  }

  .platforms-container {
    grid-template-columns: repeat(2, 1fr) !important;
    gap: 0.4rem !important;
    padding: 0.5rem !important;
    margin: 0.5rem auto !important;
    max-width: 100% !important;
  }

  .platform {
    min-height: 32px !important;
    padding: 0.4rem !important;
    flex-direction: row !important;
    gap: 0.3rem !important;
  }

  .platform svg {
    font-size: 0.9rem !important;
  }

  .platform-name {
    font-size: 0.65rem !important;
    line-height: 1 !important;
  }

  /* Ajustar los estilos de hover para dispositivos pequeños */
  .platform:hover,
  .platform:active {
    transform: translateY(-1px) scale(1.02) !important;
    box-shadow: 
      0 4px 8px -2px rgba(140, 122, 230, 0.15),
      0 2px 4px -2px rgba(140, 122, 230, 0.1) !important;
  }

  .platform:hover svg,
  .platform:active svg {
    transform: scale(1.1) !important;
  }

  .credits-section {
    gap: 0.5rem !important;
    margin: 1rem 0 !important;
  }

  .developers, .publishers {
    padding: 0.6rem !important;
    border-radius: 10px !important;
  }

  .developers h3, .publishers h3 {
    font-size: 0.7rem !important;
    margin-bottom: 0.4rem !important;
  }

  .developers p, .publishers p {
    font-size: 0.8rem !important;
    line-height: 1.3 !important;
  }

  /* Ajustar los estilos de hover para dispositivos pequeños */
  .platform:hover,
  .platform:active {
    transform: translateY(-1px) scale(1.02) !important;
    box-shadow: 
      0 4px 8px -2px rgba(140, 122, 230, 0.15),
      0 2px 4px -2px rgba(140, 122, 230, 0.1) !important;
  }

  .platform:hover svg,
  .platform:active svg {
    transform: scale(1.1) !important;
  }
}

/* Optimizar animaciones para dispositivos móviles */
@media (max-width: 480px) {
  .platform {
    transition: transform 0.2s ease, box-shadow 0.2s ease !important;
  }

  .platform svg {
    transition: transform 0.2s ease !important;
  }

  /* Deshabilitar hover states en dispositivos táctiles */
  @media (hover: none) {
    .platform:hover {
      transform: none !important;
      box-shadow: none !important;
    }

    .platform:hover svg {
      transform: none !important;
    }
  }
}

/* Eliminar estilos de tooltip en móvil */
@media (max-width: 480px) {
  .platform::after {
    display: none !important;
  }
}

.media-section {
  margin: 3rem 0;
  padding: 0;
  background: transparent;
  border-radius: 0;
  box-shadow: none;
}

.media-tabs {
  display: flex;
  gap: 2rem;
  margin-bottom: 2rem;
  border: none;
  padding-bottom: 0;
  position: relative;
  justify-content: center;
}

.tab-button {
  padding: 0.75rem 1.5rem;
  background: none;
  border: none;
  color: #a0a0a0;
  cursor: pointer;
  font-size: 0.9rem;
  font-weight: 500;
  position: relative;
  transition: all 0.3s ease;
  letter-spacing: 0.5px;
  text-transform: uppercase;
}

.tab-button::after {
  content: '';
  position: absolute;
  bottom: -2px;
  left: 50%;
  width: 0;
  height: 2px;
  background: #6c5ce7;
  transition: all 0.3s ease;
  transform: translateX(-50%);
}

.tab-button.active {
  color: #6c5ce7;
}

.tab-button.active::after {
  width: 30px;
}

.tab-button:hover {
  color: #6c5ce7;
}

.tab-button:hover::after {
  width: 20px;
  background: #6c5ce7;
}

.media-content {
  position: relative;
  min-height: 200px;
  transition: all 0.3s ease;
  margin-top: 2rem;
  padding: 0 1rem;
}

@media (max-width: 768px) {
  .media-section {
    margin: 2rem 0;
  }

  .media-tabs {
    gap: 1.5rem;
  }

  .tab-button {
    padding: 0.5rem 1rem;
    font-size: 0.85rem;
  }
}

@media (max-width: 480px) {
  .media-tabs {
    gap: 1rem;
  }

  .tab-button {
    padding: 0.5rem 0.75rem;
    font-size: 0.75rem;
  }

  .tab-button.active::after {
    width: 20px;
  }

  .tab-button:hover::after {
    width: 15px;
  }

  .media-content {
    padding: 0 0.5rem;
  }
}

/* Estilos para la galería multimedia */
.media-gallery-container {
  margin: 1rem 0;
  padding: 0;
  background: transparent;
  border-radius: 12px;
}

.images-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1rem;
  margin-bottom: 2rem;
}

.gallery-image {
  width: 100%;
  height: 180px;
  object-fit: cover;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.gallery-image:hover {
  transform: translateY(-5px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.15);
}

.video-container {
  margin-top: 1rem;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.video-frame {
  width: 100%;
  height: 450px;
  border: none;
  border-radius: 12px;
}

@media (max-width: 768px) {
  .video-frame {
    height: 350px;
  }
  
  .images-grid {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 0.75rem;
  }

  .gallery-image {
    height: 150px;
  }
}

@media (max-width: 480px) {
  .video-frame {
    height: 250px;
  }

  .images-grid {
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    gap: 0.5rem;
  }

  .gallery-image {
    height: 120px;
  }
}

.similar-games-section {
  margin-top: 2rem;
  padding: 2rem;
  border-top: 2px solid #e9ecef;
}

.similar-games-section h2 {
  font-size: 1.5rem;
  font-weight: 600;
  color: #2d3436;
  margin-bottom: 1.5rem;
  padding-left: 2rem;
  display: flex;
  align-items: center;
  gap: 0.8rem;
}

.similar-games-section h2::before {
  content: '🎮';
  font-size: 1.4rem;
}

.similar-games-grid {
  overflow: hidden;
  border-radius: 10px;
}

.actions-section {
  display: inline-flex;
  align-items: center;
  margin-left: 1.5rem;
  height: 100%;
}

.delete-button {
  margin-top: 2rem;
  padding: 0.8rem 1.5rem;
  background: #d63031;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.delete-button:hover {
  background: #c0392b;
  transform: translateY(-2px);
}

.loading-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(245, 246, 250, 0.95);
  backdrop-filter: blur(5px);
  z-index: 99999;
}

.loading-container .loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.loading-container .loader-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.loading-container .img-loading {
  max-width: 200px;
  height: auto;
}

.loading-text {
  color: #2d3436;
  font-size: 1.2em;
  margin-top: 1rem;
  text-align: center;
  font-weight: 500;
}

.error-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 60px);
  gap: 20px;
  color: #2d3436;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.delete-icon-button {
  background: rgba(255, 68, 68, 0.1);
  border: 1px solid rgba(255, 68, 68, 0.3);
  color: #ff4444;
  cursor: pointer;
  padding: 10px;
  transition: all 0.3s ease;
  font-size: 1.4rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  position: relative;
  margin: 0;
  height: 100%;
  width: 42px;
  box-shadow: 0 2px 4px rgba(255, 68, 68, 0.1);
}

.delete-icon-button::after {
  content: 'Eliminar juego';
  position: absolute;
  left: 100%;
  margin-left: 10px;
  background: rgba(255, 68, 68, 0.95);
  padding: 6px 12px;
  border-radius: 6px;
  font-size: 0.85rem;
  color: white;
  opacity: 0;
  transform: translateX(-10px);
  transition: all 0.3s ease;
  pointer-events: none;
  white-space: nowrap;
  font-weight: 500;
  box-shadow: 0 2px 8px rgba(255, 68, 68, 0.2);
}

.delete-icon-button:hover {
  color: white;
  transform: translateY(-2px);
  background-color: #ff4444;
  border-color: #ff4444;
  box-shadow: 0 4px 12px rgba(255, 68, 68, 0.25);
}

.delete-icon-button:hover::after {
  opacity: 1;
  transform: translateX(0);
}

/* Estilos base para elementos interactivos */
.metadata-item,
.platform,
.tab-button,
.delete-icon-button {
  background: rgba(255, 255, 255, 0.95);
  border: 1px solid rgba(140, 122, 230, 0.15);
  border-radius: 16px;
  box-shadow: 
    0 2px 4px rgba(140, 122, 230, 0.05),
    0 0 0 1px rgba(140, 122, 230, 0.05);
  position: relative;
  overflow: hidden;
  transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
}

.metadata-item::before,
.platform::before,
.tab-button::before,
.delete-icon-button::before {
  content: '';
  position: absolute;
  inset: 0;
  background: linear-gradient(
    45deg,
    rgba(140, 122, 230, 0.15),
    rgba(155, 89, 182, 0.15)
  );
  opacity: 0;
  transition: opacity 0.4s ease;
}

.metadata-item::after,
.platform::after,
.tab-button::after,
.delete-icon-button::after {
  content: '';
  position: absolute;
  inset: 0;
  background: radial-gradient(circle at center, rgba(140, 122, 230, 0.2) 0%, transparent 70%);
  opacity: 0;
  transition: all 0.4s ease;
  transform: scale(0.5);
}

.metadata-item:hover,
.platform:hover,
.tab-button:hover,
.delete-icon-button:hover {
  transform: translateY(-2px) scale(1.02);
  background: white;
  border-color: rgba(140, 122, 230, 0.3);
  box-shadow: 
    0 10px 20px -5px rgba(140, 122, 230, 0.2),
    0 5px 10px -5px rgba(140, 122, 230, 0.1);
}

.metadata-item:hover::before,
.platform:hover::before,
.tab-button:hover::before,
.delete-icon-button:hover::before {
  opacity: 1;
}

.metadata-item:hover::after,
.platform:hover::after,
.tab-button:hover::after,
.delete-icon-button:hover::after {
  opacity: 1;
  transform: scale(1);
}

.metadata-item:active,
.platform:active,
.tab-button:active,
.delete-icon-button:active {
  transform: translateY(0) scale(0.98);
  transition-duration: 0.1s;
}

/* Ajustes para developers y publishers */
.developers, .publishers {
  padding: 1.25rem;
  border: 1px solid rgba(140, 122, 230, 0.15);
  border-radius: 16px;
  transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
}

.developers:hover, .publishers:hover {
  border-color: rgba(140, 122, 230, 0.3);
}

.developers h3, .publishers h3 {
  color: #554769;
  position: relative;
  z-index: 1;
}

/* Ajustes para los tabs */
.tab-button {
  padding: 0.75rem 1.25rem;
  color: #554769;
  border: none;
  background: transparent;
  font-weight: 500;
  position: relative;
  overflow: visible;
}

.tab-button::after {
  content: '';
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 100%;
  height: 2px;
  background: #6c5ce7;
  transform: scaleX(0);
  transition: transform 0.4s cubic-bezier(0.4, 0, 0.2, 1);
}

.tab-button:hover::after,
.tab-button.active::after {
  transform: scaleX(1);
}

.tab-button:hover {
  color: #6c5ce7;
  transform: translateY(-1px);
}

/* Ajustes responsivos globales */
@media (max-width: 768px) {
  .interactive-element {
    border-radius: 12px;
  }

  .interactive-element:hover {
    transform: translateY(-1px) scale(1.01);
  }
}

@media (max-width: 480px) {
  .interactive-element {
    border-radius: 10px;
  }

  .interactive-element:hover {
    transform: translateY(-1px) scale(1.02);
    box-shadow: 
      0 5px 15px -5px rgba(140, 122, 230, 0.15),
      0 3px 8px -5px rgba(140, 122, 230, 0.1);
  }
}

/* Estilos hover globales - Fuera de media queries */
.platform:hover,
.platform:active {
  transform: translateY(-2px) scale(1.05) !important;
  background: white !important;
  color: #6c5ce7 !important;
  border-color: rgba(140, 122, 230, 0.3) !important;
  box-shadow: 
    0 10px 20px -5px rgba(140, 122, 230, 0.2),
    0 5px 10px -5px rgba(140, 122, 230, 0.1) !important;
}

.platform:hover svg,
.platform:active svg {
  transform: scale(1.2) !important;
  color: inherit !important;
}

/* Colores específicos para cada plataforma en hover */
.platform[href*="playstation"]:hover,
.platform[href*="playstation"]:active { color: #006FCD !important; border-color: rgba(0, 111, 205, 0.3) !important; }
.platform[href*="xbox"]:hover,
.platform[href*="xbox"]:active { color: #107C10 !important; border-color: rgba(16, 124, 16, 0.3) !important; }
.platform[href*="pc"]:hover,
.platform[href*="pc"]:active,
.platform[href*="windows"]:hover,
.platform[href*="windows"]:active { color: #00A4EF !important; border-color: rgba(0, 164, 239, 0.3) !important; }
.platform[href*="nintendo"]:hover,
.platform[href*="nintendo"]:active { color: #E60012 !important; border-color: rgba(230, 0, 18, 0.3) !important; }
.platform[href*="linux"]:hover,
.platform[href*="linux"]:active { color: #FCC624 !important; border-color: rgba(252, 198, 36, 0.3) !important; }
.platform[href*="mac"]:hover,
.platform[href*="mac"]:active { color: #555555 !important; border-color: rgba(85, 85, 85, 0.3) !important; }
.platform[href*="android"]:hover,
.platform[href*="android"]:active { color: #3DDC84 !important; border-color: rgba(61, 220, 132, 0.3) !important; }
.platform[href*="ios"]:hover,
.platform[href*="ios"]:active { color: #000000 !important; border-color: rgba(0, 0, 0, 0.3) !important; }

@media (max-width: 480px) {
  .pagination-container {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    gap: 0.5rem !important;
    margin: 1rem 0 !important;
    flex-wrap: wrap !important;
  }

  .pagination-button {
    min-width: 32px !important;
    height: 32px !important;
    padding: 0.25rem !important;
    font-size: 0.85rem !important;
    border-radius: 8px !important;
  }

  .pagination-button.active {
    transform: none !important;
    font-size: 0.85rem !important;
  }

  .pagination-button:hover {
    transform: translateY(-1px) !important;
  }

  .pagination-button.prev,
  .pagination-button.next {
    padding: 0.25rem 0.5rem !important;
    font-size: 0.8rem !important;
  }

  .pagination-button.prev svg,
  .pagination-button.next svg {
    font-size: 1rem !important;
  }

  .pagination-ellipsis {
    font-size: 0.85rem !important;
    padding: 0 0.25rem !important;
  }
}

@media (max-width: 360px) {
  .pagination-container {
    gap: 0.3rem !important;
    margin: 0.75rem 0 !important;
  }

  .pagination-button {
    min-width: 28px !important;
    height: 28px !important;
    padding: 0.2rem !important;
    font-size: 0.8rem !important;
    border-radius: 6px !important;
  }

  .pagination-button.active {
    font-size: 0.8rem !important;
  }

  .pagination-button.prev,
  .pagination-button.next {
    padding: 0.2rem 0.4rem !important;
    font-size: 0.75rem !important;
  }

  .pagination-button.prev svg,
  .pagination-button.next svg {
    font-size: 0.9rem !important;
  }

  .pagination-ellipsis {
    font-size: 0.8rem !important;
    padding: 0 0.2rem !important;
  }

  /* Ocultar algunos números de página en pantallas muy pequeñas */
  .pagination-button:not(.active):not(.prev):not(.next):nth-child(n+5):nth-child(-n+6) {
    display: none !important;
  }
}

.delete-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(8px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  opacity: 0;
  visibility: hidden;
  transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
}

.delete-modal-overlay.active {
  opacity: 1;
  visibility: visible;
}

.delete-modal {
  background: white;
  padding: 2rem;
  border-radius: 24px;
  max-width: 90%;
  width: 400px;
  position: relative;
  transform: scale(0.95) translateY(20px);
  opacity: 0;
  transition: all 0.4s cubic-bezier(0.34, 1.56, 0.64, 1);
  box-shadow: 
    0 20px 60px -20px rgba(255, 68, 68, 0.15),
    0 0 0 1px rgba(255, 68, 68, 0.05);
  text-align: center;
}

.delete-modal-overlay.active .delete-modal {
  transform: scale(1) translateY(0);
  opacity: 1;
}

.delete-icon {
  width: 64px;
  height: 64px;
  background: linear-gradient(45deg, #ff4444, #ff6b6b);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 1.5rem;
  position: relative;
  color: white;
  font-size: 2rem;
  animation: scaleIn 0.5s cubic-bezier(0.34, 1.56, 0.64, 1);
}

.delete-icon svg {
  font-size: 2rem;
  animation: fadeInIcon 0.3s ease forwards 0.2s;
  opacity: 0;
  transform: scale(0.5);
}

@keyframes fadeInIcon {
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.delete-title {
  color: #1f2937;
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 0.75rem;
  line-height: 1.3;
}

.delete-message {
  color: #6b7280;
  font-size: 1rem;
  line-height: 1.5;
  margin-bottom: 2rem;
}

.delete-buttons {
  display: flex;
  gap: 1rem;
  justify-content: center;
  width: 100%;
}

.delete-button {
  background: linear-gradient(45deg, #ff4444, #ff6b6b);
  color: white;
  border: none;
  padding: 0.875rem 1.5rem;
  border-radius: 12px;
  font-size: 0.95rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
  flex: 1;
  max-width: 160px;
  position: relative;
  overflow: hidden;
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.cancel-button {
  background: #e5e7eb;
  color: #4b5563;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.delete-button::before,
.cancel-button::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    45deg,
    transparent,
    rgba(255, 255, 255, 0.1),
    transparent
  );
  transform: translateX(-100%);
  transition: transform 0.6s ease;
}

.delete-button:hover::before,
.cancel-button:hover::before {
  transform: translateX(100%);
}

.delete-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 15px rgba(255, 68, 68, 0.2);
}

.cancel-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

/* Tablets y dispositivos medianos */
@media (max-width: 768px) {
  .delete-modal {
    width: 85%;
    padding: 1.75rem;
  }
}

/* Dispositivos móviles */
@media (max-width: 480px) {
  .delete-modal {
    padding: 1.5rem;
    width: 90%;
    border-radius: 20px;
    margin: 1rem;
  }

  .delete-icon {
    width: 56px;
    height: 56px;
    margin-bottom: 1.25rem;
  }

  .delete-icon svg {
    font-size: 1.75rem;
  }

  .delete-title {
    font-size: 1.25rem;
    margin-bottom: 0.5rem;
  }

  .delete-message {
    font-size: 0.9rem;
    margin-bottom: 1.5rem;
    padding: 0 0.5rem;
  }

  .delete-buttons {
    gap: 0.75rem;
  }

  .delete-button,
  .cancel-button {
    padding: 0.75rem 1rem;
    font-size: 0.9rem;
    border-radius: 10px;
    max-width: 130px;
    min-width: 100px;
  }
}

/* Dispositivos pequeños */
@media (max-width: 360px) {
  .delete-modal {
    padding: 1.25rem;
    width: 95%;
    border-radius: 16px;
    margin: 0.75rem;
  }

  .delete-icon {
    width: 48px;
    height: 48px;
    margin-bottom: 1rem;
  }

  .delete-icon svg {
    font-size: 1.5rem;
  }

  .delete-title {
    font-size: 1.1rem;
    margin-bottom: 0.5rem;
    padding: 0 0.25rem;
  }

  .delete-message {
    font-size: 0.85rem;
    margin-bottom: 1.25rem;
    padding: 0 0.25rem;
    line-height: 1.4;
  }

  .delete-button,
  .cancel-button {
    padding: 0.625rem 0.75rem;
    font-size: 0.85rem;
    border-radius: 8px;
    max-width: 110px;
    min-width: 90px;
  }
}
