* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  background: #ffffff;
  min-height: 100vh;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  background: #ffffff;
}

#root {
  min-height: 100vh;
  background: transparent;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --secondary-color: #536b8ba1;
  --primary-color-search: #a78bfa;
  --secondary-color-search: #dee3e79a;
  --primary-button-color: #8b5cf6;
  --primary-color: #312e81;
  --nav-color: #f8fafc;
}
