.recent-games-container, .upcoming-games-container {
    padding: 2rem;
    margin: 2rem auto;
    position: relative;
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
    max-width: 1400px;
    width: 100%;
    box-sizing: border-box;
}

.recent-games-header, .upcoming-games-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto 1.5rem;
    padding: 0 1rem 1rem;
    border-bottom: 1px solid rgba(231, 231, 237, 0.8);
    width: 100%;
}

.recent-games-container h2,
.upcoming-games-container h2,
.similar-games-container h2 {
    color: #322d69;
    font-size: 1.8rem;
    font-weight: 700;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin: 0;
    letter-spacing: -0.025em;
}

.recent-games-container h2 .game-icon,
.upcoming-games-container h2 .game-icon,
.similar-games-container h2 .game-icon {
    color: #8c7ae6;
    font-size: 1.6rem;
    animation: float 3s ease-in-out infinite;
    opacity: 0.85;
    display: flex;
    align-items: center;
}

.recent-games-container h2 .subtitle,
.upcoming-games-container h2 .subtitle,
.similar-games-container h2 .subtitle {
    font-size: 1rem;
    color: #8c7ae6;
    font-weight: 500;
    margin-left: 0.5rem;
    opacity: 0.8;
}

@keyframes float {
    0%, 100% {
        transform: translateY(0) rotate(0);
    }
    50% {
        transform: translateY(-3px) rotate(5deg);
    }
}

.recent-games-container h2 span {
    color: #8c7ae6;
    font-size: 1.6rem;
}

.recent-games-grid {
    display: flex;
    overflow-x: auto;
    gap: 1.2rem;
    padding: 0.5rem;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
    position: relative;
    margin: 0 auto;
    scrollbar-width: none;
    -ms-overflow-style: none;
    background: rgba(248, 250, 252, 0.5);
    border-radius: 12px;
    padding: 1rem;
}

.recent-games-grid::-webkit-scrollbar {
    display: none;
}

.game-card {
    flex: 0 0 auto;
    display: flex;
    align-items: center;
    gap: 1.2rem;
    padding: 1.2rem;
    border-radius: 16px;
    transform-origin: center;
    will-change: transform;
    transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
    width: 320px;
    position: relative;
    cursor: pointer;
    text-decoration: none;
    background: rgba(255, 255, 255, 0.98);
    border: 1px solid rgba(140, 122, 230, 0.1);
    box-shadow: 
        0 4px 6px -1px rgba(140, 122, 230, 0.03),
        0 2px 4px -1px rgba(140, 122, 230, 0.02),
        0 0 0 1px rgba(140, 122, 230, 0.05);
}

.game-card::before {
    content: '';
    position: absolute;
    inset: 0;
    border-radius: 20px;
    padding: 2px;
    background: linear-gradient(
        45deg,
        transparent,
        rgba(140, 122, 230, 0.3),
        transparent
    );
    -webkit-mask: 
        linear-gradient(#fff 0 0) content-box, 
        linear-gradient(#fff 0 0);
    mask: 
        linear-gradient(#fff 0 0) content-box, 
        linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    opacity: 0;
    transition: opacity 0.4s ease;
}

.game-card:hover {
    transform: translateY(-4px);
    background: rgba(255, 255, 255, 1);
    box-shadow: 
        0 20px 25px -5px rgba(140, 122, 230, 0.15),
        0 10px 10px -5px rgba(140, 122, 230, 0.1),
        0 0 0 1px rgba(140, 122, 230, 0.1);
}

.game-card:active {
    transform: scale(0.98) translateY(-2px);
    transition-duration: 0.1s;
    box-shadow: 
        0 10px 15px -5px rgba(140, 122, 230, 0.1),
        0 5px 5px -5px rgba(140, 122, 230, 0.05),
        0 0 0 1px rgba(140, 122, 230, 0.1);
}

.game-card::after {
    content: '';
    position: absolute;
    inset: 0;
    border-radius: 16px;
    background: radial-gradient(circle at center, rgba(140, 122, 230, 0.1) 0%, transparent 70%);
    opacity: 0;
    transition: opacity 0.3s ease;
    pointer-events: none;
}

.game-card:active::after {
    opacity: 1;
    transition-duration: 0.1s;
}

.game-card-image {
    width: 110px;
    height: 150px;
    border-radius: 12px;
    object-fit: cover;
    transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
    border: none;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.12);
    position: relative;
    overflow: hidden;
}

.game-card-image::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
        180deg,
        transparent 0%,
        rgba(0, 0, 0, 0.2) 100%
    );
    z-index: 1;
}

.game-card-image::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
        to bottom,
        rgba(140, 122, 230, 0.2),
        rgba(50, 45, 105, 0.3)
    );
    opacity: 0;
    transition: opacity 0.4s ease;
    z-index: 2;
}

.game-card:active .game-card-image {
    transform: scale(0.98);
    transition-duration: 0.1s;
}

.game-card:active .game-card-image::after {
    opacity: 1;
}

.game-card-content {
    flex: 1;
    min-width: 0;
    display: flex;
    flex-direction: column;
    gap: 0.7rem;
    padding-right: 0.5rem;
}

.game-card-title {
    color: #322d69;
    font-size: 1.15rem;
    font-weight: 700;
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    transition: all 0.3s ease;
    letter-spacing: -0.01em;
    position: relative;
    padding-bottom: 0.2rem;
}

.game-card-title::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 2px;
    background: linear-gradient(90deg, #8c7ae6, transparent);
    transition: width 0.3s ease;
}

.game-card:active .game-card-title::after {
    width: 100%;
    transition-duration: 0.1s;
}

.game-card-info {
    display: flex;
    align-items: center;
    gap: 1.2rem;
    font-size: 0.9rem;
    color: #554769;
    margin-top: 0.2rem;
    position: relative;
}

.game-card-rating {
    display: flex;
    align-items: center;
    gap: 0.3rem;
    color: #f6c90e;
    font-weight: 600;
    padding: 0.35rem 0.7rem;
    background: rgba(246, 201, 14, 0.12);
    border-radius: 10px;
    transition: all 0.3s ease;
    font-size: 0.85rem;
    box-shadow: 0 2px 4px rgba(246, 201, 14, 0.1);
}

.game-card:active .game-card-rating {
    transform: scale(0.95);
    transition-duration: 0.1s;
}

.game-card-genres {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    margin-top: 0.3rem;
}

.game-card-genre {
    color: #554769;
    font-size: 0.8rem;
    font-weight: 500;
    background: rgba(140, 122, 230, 0.08);
    padding: 0.35rem 0.8rem;
    border-radius: 10px;
    transition: all 0.3s ease;
    letter-spacing: 0.02em;
    position: relative;
    overflow: hidden;
}

.game-card-genre::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
        45deg,
        transparent,
        rgba(140, 122, 230, 0.1),
        transparent
    );
    transform: translateX(-100%);
    transition: transform 0.6s ease;
}

.game-card:active .game-card-genre {
    transform: scale(0.95);
    transition-duration: 0.1s;
}

.game-card:active .game-card-genre::before {
    transform: translateX(100%);
}

.scroll-indicators {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    transform: translateY(-50%);
    display: flex;
    justify-content: space-between;
    pointer-events: none;
    padding: 0 1rem;
}

.scroll-button {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.9);
    border: 1px solid rgba(231, 231, 237, 0.8);
    color: #322d69;
    font-size: 1.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    pointer-events: auto;
    transition: all 0.3s ease;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.scroll-button:hover {
    background: #8c7ae6;
    color: white;
    border-color: #8c7ae6;
    transform: scale(1.05);
}

.scroll-button.left {
    margin-right: auto;
}

.scroll-button.right {
    margin-left: auto;
}

.can-scroll-left .scroll-button.left,
.can-scroll-right .scroll-button.right {
    opacity: 1;
    transform: translateX(0);
}

.scroll-button.left {
    opacity: 0;
    transform: translateX(-20px);
}

.scroll-button.right {
    opacity: 0;
    transform: translateX(20px);
}

/* Mejoras responsive para tablets y móviles */
@media (max-width: 768px) {
    .recent-games-container, .upcoming-games-container {
        padding: 0.5rem 0.25rem;
        margin: 0.5rem auto;
    }

    .recent-games-header, .upcoming-games-header {
        padding: 0 0.25rem 0.25rem;
        margin-bottom: 0.5rem;
    }

    .recent-games-container h2,
    .upcoming-games-container h2,
    .similar-games-container h2 {
        font-size: 1.2rem;
    }

    .recent-games-container h2 .game-icon,
    .upcoming-games-container h2 .game-icon,
    .similar-games-container h2 .game-icon {
        font-size: 1.1rem;
    }

    .recent-games-container h2 .subtitle,
    .upcoming-games-container h2 .subtitle,
    .similar-games-container h2 .subtitle {
        font-size: 0.8rem;
    }

    .game-list-item {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0.75rem;
        gap: 1rem;
        background: rgba(255, 255, 255, 0.5);
    }

    .game-thumbnail {
        width: 120px;
        height: 68px;
        margin-right: 1rem;
        flex-shrink: 0;
    }

    .game-info {
        flex: 1;
        min-width: 0;
    }

    .game-title {
        font-size: 1.1rem;
        margin-bottom: 0.6rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .game-details {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 0.8rem;
        flex-wrap: nowrap;
    }

    .platforms-list {
        display: flex;
        gap: 0.5rem;
        flex-wrap: wrap;
        width: auto;
    }
}

@media (max-width: 480px) {
    .recent-games-container, .upcoming-games-container {
        padding: 0.35rem 0.25rem;
        margin: 0.35rem auto;
    }

    .month-header-container {
        padding: 0.35rem;
        gap: 0.25rem;
        margin-bottom: 0.35rem;
        background: rgba(255, 255, 255, 0.3);
    }

    .month-year-badge {
        padding: 0.25rem 0.4rem;
        font-size: 0.8rem;
    }

    .games-list {
        gap: 0.25rem;
        padding: 0;
    }

    .game-list-item {
        display: flex;
        flex-direction: column;
        padding: 0.75rem;
        background: rgba(255, 255, 255, 0.95);
        border-radius: 12px;
        gap: 0.5rem;
        box-shadow: 0 2px 8px rgba(140, 122, 230, 0.08);
        margin-bottom: 0.75rem;
        border: 1px solid rgba(140, 122, 230, 0.1);
    }

    .game-info {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .game-details {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .game-title {
        font-size: 1rem;
        line-height: 1.3;
        margin: 0;
        color: #322d69;
        font-weight: 700;
        text-align: left;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        letter-spacing: -0.01em;
    }

    .release-date {
        font-size: 0.8rem;
        padding: 0.35rem 0.75rem;
        background: rgba(140, 122, 230, 0.08);
        border-radius: 20px;
        color: #8c7ae6;
        align-self: flex-start;
        font-weight: 600;
        display: flex;
        align-items: center;
        gap: 0.4rem;
    }

    .game-image-container {
        width: 100%;
        height: 180px;
        border-radius: 10px;
        overflow: hidden;
        position: relative;
        box-shadow: 0 2px 8px rgba(50, 45, 105, 0.12);
    }

    .game-thumbnail {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: transform 0.3s ease;
    }

    .game-image-container img {
        all: unset;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .game-hover-info {
        display: none;
    }

    .game-list-item:active .game-image-container img {
        transform: scale(1.02);
    }

    .platforms-list {
        display: flex;
        flex-wrap: wrap;
        gap: 0.35rem;
        padding: 0;
        width: 100%;
    }

    .platform-tag {
        font-size: 0.75rem;
        padding: 0.25rem 0.5rem;
        border-radius: 6px;
        background: rgba(50, 45, 105, 0.04);
        color: #554769;
        font-weight: 500;
        border: 1px solid rgba(140, 122, 230, 0.08);
        display: inline-flex;
        align-items: center;
        white-space: nowrap;
    }

    .month-header-container {
        background: rgba(255, 255, 255, 0.8);
        backdrop-filter: blur(8px);
        -webkit-backdrop-filter: blur(8px);
        border-radius: 12px;
        padding: 0.75rem;
        margin-bottom: 1rem;
        border: 1px solid rgba(140, 122, 230, 0.15);
    }

    .month-year-badge {
        background: linear-gradient(135deg, #8c7ae6, #322d69);
        color: white;
        padding: 0.5rem 1rem;
        border-radius: 8px;
        font-weight: 600;
        font-size: 0.9rem;
        box-shadow: 0 2px 6px rgba(140, 122, 230, 0.2);
    }

    .empty-state {
        padding: 1rem;
        margin: 0.5rem 0;
    }

    .empty-state p {
        font-size: 0.9rem;
    }
}

@media (max-width: 360px) {
    .recent-games-container, .upcoming-games-container {
        padding: 0.25rem 0.15rem;
    }

    .game-list-item {
        padding: 0.6rem;
        gap: 0.4rem;
    }

    .game-title {
        font-size: 0.95rem;
    }

    .release-date {
        font-size: 0.75rem;
        padding: 0.3rem 0.6rem;
    }

    .game-image-container {
        height: 150px;
    }

    .game-thumbnail {
        height: 100%;
    }

    .game-image-container img {
        height: 100%;
    }

    .platform-tag {
        font-size: 0.7rem;
        padding: 0.2rem 0.4rem;
    }

    .month-header-container {
        padding: 0.6rem;
        margin-bottom: 0.75rem;
    }

    .month-year-badge {
        padding: 0.4rem 0.8rem;
        font-size: 0.85rem;
    }

    .games-count {
        font-size: 0.7rem;
        padding: 0.15rem 0.3rem;
    }
}

/* Estilos para el estado de carga */
.recent-games-container.loading .loading-skeleton {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 1rem;
    padding: 1rem;
}

.skeleton-card {
    display: flex;
    gap: 1.2rem;
    padding: 1.2rem;
    background: rgba(255, 255, 255, 0.8);
    border-radius: 16px;
    margin-bottom: 1rem;
    animation: loading 1.5s infinite;
}

.skeleton-image {
    width: 110px;
    height: 150px;
    background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
    border-radius: 12px;
}

.skeleton-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
}

.skeleton-title {
    height: 24px;
    background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
    border-radius: 4px;
    width: 80%;
}

.skeleton-text {
    height: 16px;
    background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
    border-radius: 4px;
    width: 60%;
}

.empty-state {
    text-align: center;
    padding: 3rem;
    background: rgba(255, 255, 255, 0.8);
    border-radius: 16px;
    margin: 2rem 0;
}

.empty-state p {
    color: #666;
    font-size: 1.1rem;
    margin: 0;
}

.game-image-container {
    position: relative;
    overflow: hidden;
    border-radius: 12px;
    transition: transform 0.3s ease;
}

.game-hover-info {
    position: absolute;
    inset: 0;
    background: rgba(140, 122, 230, 0.9);
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: opacity 0.3s ease;
}

.view-details {
    color: white;
    font-weight: 600;
    padding: 0.5rem 1rem;
    border: 2px solid white;
    border-radius: 20px;
    transform: translateY(10px);
    transition: transform 0.3s ease;
}

.game-list-item:hover .game-hover-info {
    opacity: 1;
}

.game-list-item:hover .view-details {
    transform: translateY(0);
}

.platform-tag.more {
    background: rgba(140, 122, 230, 0.2);
    color: #8c7ae6;
    font-weight: 600;
}

@media (max-width: 768px) {
    .game-hover-info {
        display: none;
    }
    
    .empty-state {
        padding: 2rem;
        margin: 1rem 0;
    }
    
    .skeleton-card {
        padding: 1rem;
    }
}

/* Estilos para el estado de error */
.recent-games-container.error {
    background-color: rgba(255, 0, 0, 0.1);
    border-radius: 8px;
    padding: 1rem;
}

.subtitle.error {
    color: #dc3545;
    font-weight: 500;
}

.month-group {
    margin-bottom: 2.5rem;
    border-radius: 16px;
    padding: 1.5rem;
}

.month-header {
    font-size: 1.4rem;
    color: #322d69;
    padding-bottom: 1rem;
    border-bottom: 2px solid #8c7ae6;
    margin-bottom: 1.5rem;
    font-weight: 700;
    letter-spacing: -0.025em;
}

.games-list {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.game-list-item {
    display: flex;
    align-items: center;
    padding: 1rem;
    border-radius: 12px;
    transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
    text-decoration: none;
    color: #322d69;
    border: 1px solid rgba(140, 122, 230, 0.1);
    background: transparent;
}

.game-list-item:hover {
    transform: translateY(-2px);
    background: rgba(255, 255, 255, 0.5);
    box-shadow: 
        0 20px 25px -5px rgba(140, 122, 230, 0.15),
        0 10px 10px -5px rgba(140, 122, 230, 0.1);
}

.game-thumbnail {
    width: 120px;
    height: 68px;
    object-fit: cover;
    border-radius: 8px;
    margin-right: 1.5rem;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.12);
    transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
}

.game-info {
    flex: 1;
}

.game-title {
    font-size: 1.2rem;
    margin-bottom: 0.8rem;
    color: #322d69;
    font-weight: 600;
    letter-spacing: -0.01em;
}

.game-details {
    display: flex;
    align-items: center;
    gap: 1rem;
    font-size: 0.9rem;
}

.release-date {
    color: #554769;
    font-weight: 500;
    padding: 0.35rem 0.7rem;
    background: rgba(140, 122, 230, 0.08);
    border-radius: 10px;
}

.platforms-list {
    display: flex;
    gap: 0.5rem;
    flex-wrap: wrap;
}

.platform-tag {
    background: rgba(140, 122, 230, 0.08);
    color: #554769;
    padding: 0.35rem 0.8rem;
    border-radius: 10px;
    font-size: 0.8rem;
    font-weight: 500;
    letter-spacing: 0.02em;
    transition: all 0.3s ease;
}

.platform-tag:hover {
    background: rgba(140, 122, 230, 0.15);
}

/* Responsive */
@media (max-width: 768px) {
    .upcoming-games-container {
        padding: 1rem;
    }

    .month-header {
        font-size: 1.3rem;
        padding-bottom: 0.8rem;
        margin-bottom: 1.2rem;
    }

    .month-group {
        padding: 1.2rem;
        margin-bottom: 2rem;
    }
}

@media (max-width: 480px) {
    .upcoming-games-container {
        padding: 0.75rem;
    }

    .month-header {
        font-size: 1.2rem;
        padding-bottom: 0.6rem;
        margin-bottom: 1rem;
    }

    .month-group {
        padding: 1rem;
        margin-bottom: 1.5rem;
    }
}

@media (max-width: 360px) {
    .upcoming-games-container {
        padding: 0.5rem;
    }

    .month-header {
        font-size: 1.1rem;
        padding-bottom: 0.5rem;
        margin-bottom: 0.8rem;
    }

    .month-group {
        padding: 0.8rem;
        margin-bottom: 1.2rem;
    }
}

.month-header-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1.5rem;
    padding: 0.5rem;
    background: rgba(255, 255, 255, 0.5);
    border-radius: 16px;
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
    border: 1px solid rgba(140, 122, 230, 0.15);
}

.month-year-badge {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0.8rem 1.2rem;
    background: linear-gradient(135deg, #8c7ae6 0%, #322d69 100%);
    border-radius: 12px;
    box-shadow: 
        0 4px 6px -1px rgba(140, 122, 230, 0.2),
        0 2px 4px -1px rgba(140, 122, 230, 0.1);
    position: relative;
    overflow: hidden;
}

.month-year-badge::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(45deg, transparent, rgba(255, 255, 255, 0.1), transparent);
    transform: translateX(-100%);
    animation: shimmer 2s infinite;
}

@keyframes shimmer {
    100% {
        transform: translateX(100%);
    }
}

.month-year-badge .month {
    color: white;
    font-size: 1.4rem;
    font-weight: 700;
    text-transform: capitalize;
    letter-spacing: -0.02em;
    line-height: 1.2;
}

.month-year-badge .year {
    color: rgba(255, 255, 255, 0.9);
    font-size: 1rem;
    font-weight: 500;
    opacity: 0.9;
}

.month-stats {
    display: flex;
    align-items: center;
    gap: 1rem;
    padding: 0 1rem;
}

.games-count {
    color: #322d69;
    font-size: 0.95rem;
    font-weight: 600;
    padding: 0.5rem 1rem;
    background: rgba(140, 122, 230, 0.1);
    border-radius: 20px;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    transition: all 0.3s ease;
}

.games-count:hover {
    background: rgba(140, 122, 230, 0.15);
    transform: translateY(-1px);
}

@media (max-width: 768px) {
    .month-header-container {
        flex-direction: column;
        gap: 1rem;
        padding: 1rem;
    }

    .month-year-badge {
        width: 100%;
        align-items: center;
        text-align: center;
    }

    .month-stats {
        width: 100%;
        justify-content: center;
        padding: 0;
    }

    .games-count {
        width: 100%;
        justify-content: center;
    }
} 
