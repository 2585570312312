.cont-cards-vertical {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  gap: 1.5rem;
  padding: 1rem;
  margin: 0 auto;
  max-width: 1400px;
  width: 95%;
  justify-content: center;
  place-items: center;
}

/* Mejoras responsive para diferentes tamaños de pantalla */
@media screen and (min-width: 1440px) {
  .cont-cards-vertical {
    grid-template-columns: repeat(4, 1fr);
    gap: 2.5rem;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1439px) {
  .cont-cards-vertical {
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .cont-cards-vertical {
    grid-template-columns: repeat(2, 1fr);
    gap: 1.5rem;
    padding: 0.75rem;
    width: 98%;
  }
}

@media screen and (max-width: 767px) {
  .cont-cards-vertical {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 0.875rem;
    padding: 0.75rem;
    width: 100%;
    margin: 0;
  }
}

@media screen and (max-width: 480px) {
  .cont-cards-vertical {
    grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
    gap: 0.75rem;
    padding: 0.5rem;
  }
}

@media screen and (max-width: 360px) {
  .cont-cards-vertical {
    grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
    gap: 0.5rem;
    padding: 0.375rem;
    width: calc(100% - 0.5rem);
    margin: 0 auto;
  }
}

.cont-cards-horizontal {
  display: flex;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  padding: 1.5rem;
  gap: 1.5rem;
  scroll-padding: 1rem;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: thin;
}

/* Mejoras en el scroll horizontal para móviles */
@media screen and (max-width: 768px) {
  .cont-cards-horizontal {
    padding: 0.75rem;
    gap: 0.875rem;
    scroll-padding: 0.75rem;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
  }

  .cont-cards-horizontal::after {
    content: '';
    padding-right: 0.75rem;
  }
}

@media screen and (max-width: 480px) {
  .cont-cards-horizontal {
    padding: 0.5rem;
    gap: 0.625rem;
    scroll-padding: 0.5rem;
  }
}

@media screen and (max-width: 360px) {
  .cont-cards-horizontal {
    padding: 0.375rem;
    gap: 0.5rem;
    scroll-padding: 0.375rem;
  }
}

.cont-cards-horizontal::-webkit-scrollbar {
  height: 6px;
}

.cont-cards-horizontal::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 3px;
}

.cont-cards-horizontal::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 3px;
}

.cont-cards-horizontal::-webkit-scrollbar-thumb:hover {
  background: #555;
}
