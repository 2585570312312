.contenedor-component {
  width: 100%;
  height: 280px;
  background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    url("../../img/banner.jpg");
  background-position: center;
  background-size: auto 100%;
  background-repeat: repeat-x;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.4s ease-in-out;
}

.search-container {
  display: flex;
  align-items: center;
  gap: 0.6rem;
  background: rgba(255, 255, 255, 0.85);
  padding: 0.6rem 0.8rem;
  border-radius: 30px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
  backdrop-filter: blur(4px);
  transform: translateY(0);
  transition: all 0.3s ease-in-out;
  border: 1px solid rgba(255, 255, 255, 0.18);
}

.search-container:hover {
  transform: translateY(-1px);
  box-shadow: 0 6px 25px rgba(0, 0, 0, 0.1);
  background: rgba(255, 255, 255, 0.9);
}

.search-bar {
  width: 380px;
  height: 42px;
  padding: 0 1.2rem;
  border: 1px solid rgba(233, 236, 239, 0.8);
  border-radius: 25px;
  font-size: 0.95rem;
  color: #495057;
  background: rgba(255, 255, 255, 0.9);
  transition: all 0.3s ease-in-out;
}

.search-bar:focus {
  outline: none;
  border-color: #8c7ae6;
  background: #ffffff;
  box-shadow: 0 0 0 3px rgba(140, 122, 230, 0.1);
}

.search-bar::placeholder {
  color: #adb5bd;
  font-weight: 300;
  font-size: 0.92rem;
}

.search-button {
  padding: 0.7rem 1.6rem;
  border: none;
  border-radius: 25px;
  background: linear-gradient(45deg, #8c7ae6, #9b59b6);
  color: white;
  font-size: 0.92rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  letter-spacing: 0.3px;
  text-transform: none;
}

.search-button:hover:not(:disabled) {
  transform: translateY(-1px);
  box-shadow: 0 4px 15px rgba(140, 122, 230, 0.2);
  background: linear-gradient(45deg, #7f6ee3, #8e44ad);
}

.search-button:disabled {
  opacity: 0.6;
  cursor: not-allowed;
  background: #ced4da;
}

@media (max-width: 768px) {
  .contenedor-component {
    height: 180px;
  }

  .search-container {
    padding: 0.5rem 0.7rem;
    gap: 0.5rem;
    margin: 0 1rem;
    width: 90%;
    max-width: 450px;
  }

  .search-bar {
    width: 100%;
    height: 38px;
    font-size: 0.9rem;
  }

  .search-button {
    padding: 0.6rem 1.2rem;
    font-size: 0.85rem;
    white-space: nowrap;
  }
}

@media (min-width: 1921px) {
  .contenedor-component {
    height: 380px;
  }

  .search-container {
    max-width: 800px;
    padding: 0.8rem 1rem;
  }

  .search-bar {
    width: 600px;
    height: 48px;
    font-size: 1.1rem;
    padding: 0 1.5rem;
  }

  .search-button {
    padding: 0.85rem 2rem;
    font-size: 1.1rem;
  }
}
