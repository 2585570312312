.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2rem 0 3.5rem;
}

.pagination > div {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem;
  background: rgba(255, 255, 255, 0.95);
  border-radius: 16px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
  backdrop-filter: blur(4px);
  border: 1px solid rgba(140, 122, 230, 0.15);
  transition: all 0.3s ease;
}

.pagination > div:hover {
  box-shadow: 0 6px 25px rgba(140, 122, 230, 0.15);
  border-color: rgba(140, 122, 230, 0.3);
  transform: translateY(-1px);
}

.pagination-button {
  min-width: 38px;
  height: 38px;
  border: none;
  border-radius: 12px;
  background-color: rgba(255, 255, 255, 0.9);
  color: #554769;
  font-size: 0.95rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pagination-button:hover {
  background-color: rgba(108, 92, 231, 0.1);
  color: #6c5ce7;
  transform: translateY(-1px);
}

.pagination-button-current {
  min-width: 38px;
  height: 38px;
  border: none;
  border-radius: 12px;
  background: linear-gradient(45deg, #6c5ce7, #8c7ae6);
  color: white;
  font-size: 0.95rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 12px rgba(108, 92, 231, 0.2);
}

.pagination-button-current:hover {
  transform: translateY(-1px);
  box-shadow: 0 6px 15px rgba(108, 92, 231, 0.25);
}

.pagination-NextPrevious {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 38px;
  height: 38px;
  border: none;
  border-radius: 12px;
  background: rgba(255, 255, 255, 0.9);
  color: #6c5ce7;
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 1.1rem;
}

.pagination-NextPrevious:hover {
  background-color: rgba(108, 92, 231, 0.1);
  color: #6c5ce7;
  transform: translateY(-1px);
}

.pagination-NextPrevious[hidden] {
  visibility: hidden;
}

.extra-left-padding {
  margin-left: 0;
  font-size: 1.2rem;
}

@media (max-width: 768px) {
  .pagination {
    margin: 1.5rem 0 3rem;
  }

  .pagination > div {
    gap: 0.4rem;
    padding: 0.6rem;
  }

  .pagination-button,
  .pagination-button-current,
  .pagination-NextPrevious {
    min-width: 34px;
    height: 34px;
    font-size: 0.9rem;
  }
}

@media (max-width: 480px) {
  .pagination {
    margin: 1rem 0 2.5rem;
  }

  .pagination > div {
    gap: 0.3rem;
    padding: 0.5rem;
  }

  .pagination-button,
  .pagination-button-current,
  .pagination-NextPrevious {
    min-width: 32px;
    height: 32px;
    font-size: 0.85rem;
    border-radius: 10px;
  }
}
