.home-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

main {
  flex: 1;
  width: 100%;
  margin: 0 auto;
  position: relative;
}

.games-section {
  position: relative;
  margin: 2rem 0;
  min-height: 400px;
}

.featured-sections {
  margin: 2rem 0;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.loading-state {
  position: absolute;
  inset: 0;
  background: rgba(255, 255, 255, 0.95);
  border-radius: 12px;
  padding: 2rem;
  overflow: hidden;
}

.loading-container {
  text-align: center;
  margin-bottom: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
}

.loading-container p {
  color: #2d3436;
  font-size: 1.1rem;
  margin: 0;
}

.loading-skeleton {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 2rem;
  width: 100%;
}

.skeleton-card {
  background: white;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  animation: pulse 1.5s ease-in-out infinite;
}

.skeleton-image {
  width: 100%;
  height: 200px;
  background: #f0f0f0;
}

.skeleton-content {
  padding: 1rem;
}

.skeleton-title {
  height: 24px;
  background: #f0f0f0;
  margin-bottom: 1rem;
  border-radius: 4px;
  width: 80%;
}

.skeleton-text {
  height: 16px;
  background: #f0f0f0;
  margin-bottom: 0.5rem;
  border-radius: 4px;
  width: 60%;
}

.skeleton-rating {
  height: 16px;
  background: #f0f0f0;
  border-radius: 4px;
  width: 40%;
}

@keyframes pulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

.error-state {
  text-align: center;
  padding: 2rem 1rem;
}

@media (max-width: 768px) {
  .games-section {
    margin: 1.5rem 0;
  }

  .loading-state {
    padding: 1.5rem;
  }

  .loading-skeleton {
    grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
    gap: 1.5rem;
  }
}

@media (max-width: 480px) {
  main {
    padding: 0.5rem;
  }

  .games-section {
    margin: 1rem 0;
  }

  .loading-state {
    padding: 1rem;
  }

  .loading-skeleton {
    grid-template-columns: 1fr;
    gap: 1rem;
  }

  .loading-container p {
    font-size: 0.95rem;
  }
}

@media (max-width: 360px) {
  main {
    padding: 0.25rem;
  }

  .loading-state {
    padding: 0.75rem;
  }

  .loading-container p {
    font-size: 0.9rem;
  }
}

@media (min-width: 1921px) {
  main {
    max-width: 1800px;
    padding: 0 2rem;
  }

  .games-section {
    margin: 3rem 0;
  }

  .featured-sections {
    margin: 3rem 0;
    gap: 3rem;
  }

  .loading-skeleton {
    grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
    gap: 2.5rem;
  }
}
