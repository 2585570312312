.empty-gif {
  position: center;
  width: 200px;
  height: 200px;
  margin-bottom: 1.5rem;
}

.empty-results-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 3rem 1rem;
  text-align: center;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 16px;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.06);
  backdrop-filter: blur(10px);
  margin: 2rem auto;
  max-width: 600px;
}

.empty-message {
  font-size: 1.2rem;
  color: #2d3436;
  margin-bottom: 1rem;
  line-height: 1.5;
}

.empty-suggestion {
  font-size: 1rem;
  color: #636e72;
  margin-bottom: 2rem;
  line-height: 1.4;
}

@media (max-width: 768px) {
  .empty-results-container {
    margin: 1rem;
    padding: 2rem 1rem;
  }

  .empty-gif {
    width: 150px;
    height: 150px;
  }

  .empty-message {
    font-size: 1.1rem;
  }

  .empty-suggestion {
    font-size: 0.9rem;
  }
}
