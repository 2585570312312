.custom-button {
  background-color: var(--primary-button-color);
  border-radius: 8px !important;
  padding: 12px 32px;
  height: 44px;
  text-align: center;
  color: white;
  font-size: 14px;
  border: 0;
  font-weight: 600;
  margin: 0.1rem;
  cursor: pointer;
  animation: none;
}

.custom-button:hover {
  background-color: var(--secondary-color);
  color: white;
}

.custom-button:disabled {
  background-color: rgba(97, 97, 97, 0.971);
}

.custom-buttonIcon {
  background-color: var(--primary-button-color);
  border-radius: 8px;
  padding: 6px 32px;
  height: 44px;
  text-align: center;
  color: white;
  font-size: 14px;
  border: 0;
  font-weight: 600;
  cursor: pointer;
  animation: none;
}

.custom-buttonIcon:hover {
  background-color: var(--secondary-color);
  color: white;
}



.home-button {
  width: 24px;
  height: 24px;
  margin-left: 20px;
}

.icon-button {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}

.delete-button {
  background-color: red;
  border-radius: 8px;
  padding: 10px 30px;
  height: 44px;
  text-align: center;
  color: white;
  font-size: 14px;
  border: 0;
  font-weight: 800;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
}

.middle-align {
  vertical-align: middle;
}

.delete-button:hover {
  background-color: rgb(175, 0, 0);
}

.button-icon {
  padding-right: 8px;
}

.icon-emptyResults {
  width: 60px;
  height: 60px;
}

.custom-button span {
  display: flex;
  align-items: center;
  gap: 8px;
}
